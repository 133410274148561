import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import useFetch from "./useFetch";

/**
 * Custom hook for searching through data, either locally or via an API, with support for
 * debounce and conditional triggers (on input change or on pressing Enter).
 *
 * @param {Object} options - Options for configuring the search behavior.
 * @param {Array} options.data - The dataset to search through locally.
 * @param {string} [options.url] - The API endpoint to call for remote search.
 * @param {string} [options.queryParam="search"] - The query parameter to use in the API request.
 * @param {Array<string>} [options.searchKeys=["name", "urlCode"]] - The keys to search within when
 *  filtering the local dataset.
 * @param {"onChange"|"onEnter"} [options.triggerOn="onChange"] - Determines whether the
 * search should trigger on every input change or only when Enter is pressed.
 * @returns {Object} The search hook's state and actions.
 * @returns {string} query - The current search query string.
 * @returns {function} setQuery - Function to update the query state.
 * @returns {Array} filteredData - The filtered data based on the search query.
 * @returns {boolean} loading - The loading state of the search (true when fetching data).
 * @returns {string|null} error - The error state of the search (null if no errors).
 * @returns {function} fetchResults - Function to manually trigger the search process.
 */

const useSearch = ({
  data = [],
  url = "",
  queryParam = "saerch",
  searchKeys = ["name", "urlCode"],
  debounceDelay = 300, // delay for debouncing in milliseconds
  triggerOn = "onChange",
}) => {
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [searchUrl, setSearchUrl] = useState("");
  const tokens = useSelector((state) => state?.tokens.value);
  const isFirstLoad = useRef(true);

  const {
    data: apiData,
    loading,
    error,
    fetchData,
  } = useFetch(searchUrl, tokens, [searchUrl], [], false);

  const fetchSearchedResults = useCallback(async () => {
    if (isFirstLoad) {
      isFirstLoad.current = false;
    }

    const handler = setTimeout(() => {
      if (url) {
        const searchEndpoint = `${url}?${queryParam}=${query}`;
        setSearchUrl(searchEndpoint);
        fetchData(searchEndpoint);
      } else {
        const filtered = data.filter((item) =>
          searchKeys.some((key) =>
            item[key]?.toString().toLowerCase().includes(query.toLowerCase())
          )
        );
        setFilteredData(filtered);
      }
    }, debounceDelay);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (triggerOn === "onChange" && query) {
      fetchSearchedResults();
    } else if (!query) {
      setFilteredData(data);
    }
  }, [query]);

  useEffect(() => {
    if (apiData && url) {
      setFilteredData(apiData);
    }
  }, [apiData, url]);

  return {
    query,
    setQuery,
    filteredData,
    loading,
    error,
    fetchSearchedResults,
  };
};

export default useSearch;
