import { Button, Drawer, IconButton, Stack } from "@mui/material";
import React from "react";
import { PageSubheader } from "./Typography";
import { CloseIcon } from "./utils/InsyncIconsUtil";

/**
 * A sidebar component that contains a drawer and a button to toggle the drawer.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The content to display inside the drawer.
 * @param {("left"|"right"|"top"|"bottom")} [props.anchor="right"] - The side of the screen
 * from which the drawer will slide.
 * @param {string} [props.button=props.anchor] - The text of the button which opens the drawer.
 * @param {Object} [props.drawerProps={}] - Additional props to pass to the Drawer component.
 *
 * @returns {JSX.Element} The rendered sidebar component.
 */

export default function SideBar({
  children,
  anchor = "right",
  button = anchor,
  drawerProps = {},
  onButtonClick = () => {},
  buttonSx = {},
  paperProps = {},
  windowWidth = "45%",
  title = "",
  footer = "",
}) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (updatedOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!open) {
      onButtonClick();
    }

    setOpen(updatedOpen);
  };

  return (
    <React.Fragment key={anchor + "-anchor-drawer-key"}>
      <Button sx={{ ...buttonSx }} onClick={toggleDrawer(true)}>
        {button}
      </Button>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: 21474836 }}
        PaperProps={{
          sx: {
            marginTop: "67px",
            width: windowWidth,
            minWidth: "600px",
            maxWidth: "1200px",
          },
          ...paperProps,
        }}
        {...drawerProps}
      >
        <Stack
          sx={{
            borderBottom: "1px solid #D8DCE2",
            position: "sticky",
            top: 0,
            zIndex: 99999999999999,
            background: "white",
          }}
        >
          <Stack
            justifyContent={"space-between"}
            flexDirection={"row"}
            alignItems={"center"}
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <PageSubheader>{title}</PageSubheader>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack flexDirection={"column"}>
          <Stack
            flexDirection={"column"}
            sx={{ padding: "24px", marginBottom: "67px" }}
          >
            {children}
          </Stack>
        </Stack>
        <Stack
          sx={{
            position: "sticky",
            bottom: "50px",
            minHeight: "60px",
            zIndex: 9999999999,
            background: "white",
          }}
        >
          {footer}
        </Stack>
      </Drawer>
    </React.Fragment>
  );
}
