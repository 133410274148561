import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import usePatchRequest from "../../hooks/usePatchRequest";
import SectionTopBar from "../../components/SectionTopBar";
import Table from "../../components/Table";
import { CaptionS, PageHeader } from "../../components/Typography";
import TabularLoadingPlaceholder from "../../components/LoadingPlaceholders/TabularLoadingPlaceholder";
import {
  FILES_CLOUDFRONT_BASE_URL,
  FILES_COMPLETE_UPLOAD_ENDPOINT,
  FILES_GET_ENDPOINT,
  FILES_PRESIGNED_URL_ENDPOINT,
} from "../../constants/Constants";
import FileUploadPreSignedUrl from "../../components/FileUpload/FileUploadPreSignedUrl";

export default function Files() {
  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const [refreshKey, setRefreshKey] = useState(0);

  // Fetching files data from the API
  const { data, loading, error } = useFetch(
    `${FILES_GET_ENDPOINT}?app_id=${currentApp.id}`,
    tokens,
    [refreshKey]
  );

  // Initialize the usePatchRequest hook
  const { sendPatchRequest } = usePatchRequest(tokens);

  // Function to refresh data
  const refreshData = () => {
    setRefreshKey((prev) => prev + 1);
  };

  // Function to handle saving tags
  const handleSaveTags = async (fileId, newTags) => {
    // Ensure newTags is an array
    if (!Array.isArray(newTags)) {
      newTags = [newTags];
    }

    // Clean up tags to remove any extra whitespace or quotes
    const cleanedTags = newTags.map((tag) =>
      tag
        .toString()
        .trim()
        .replace(/^['"]+|['"]+$/g, "")
    );

    try {
      const url = `/v1/files/${fileId}`;

      await sendPatchRequest(url, {
        app_id: currentApp.id,
        tags: cleanedTags,
      });

      refreshData();
    } catch (error) {
      console.error("Error updating tags:", error);
    }
  };

  return (
    <>
      <PageHeader>Knowledge Pool: Files</PageHeader>
      <SectionTopBar
        style={{ justifyContent: "space-between", marginTop: "30px" }}
      >
        <TextField
          placeholder="Search"
          margin="dense"
          sx={{
            width: "300px",
            maxHeight: "46px",
            margin: "0px",
            "& input": {
              padding: "10px 20px !important",
            },
          }}
        />
        <FileUploadPreSignedUrl
          field={{
            name: "fileUpload",
            title: "+ Upload Files",
            multiple: true,
          }}
          accept=".pdf"
          presignUrlEndpoint={FILES_PRESIGNED_URL_ENDPOINT}
          completeUploadEndpoint={FILES_COMPLETE_UPLOAD_ENDPOINT}
          cloudFrontBaseUrl={FILES_CLOUDFRONT_BASE_URL}
          // Optional: Refresh after presigning URLs
          onPresignSuccess={refreshData}
          // Optional: Refresh after upload completion
          onUploadComplete={refreshData}
        />
      </SectionTopBar>

      {loading ? (
        <TabularLoadingPlaceholder numRows={5} numCols={6} />
      ) : error ? (
        <CaptionS color="error">Error: {error.message}</CaptionS>
      ) : (
        <Table
          customTableStyle={{
            width: "100%",
            marginTop: "30px",
          }}
          customStyle={{
            marginRight: "40px",
            overflowX: "visible",
          }}
          emptyText="No files uploaded yet."
          tableContents={data.files.map((file) => [
            {
              element: { value: file.file_name },
              id: `file_name-${file.id}`,
            },
            {
              element: { value: file.status },
              id: `status-${file.id}`,
            },
            {
              id: `tags-${file.id}`,
              element: {
                type: "tags",
                options: ["sandbox", "production"],
                value: file.tags || [],
                saveButtonClick: (newTags) => {
                  handleSaveTags(file.id, newTags);
                },
                cancelButtonClick: () => {},
              },
            },
            {
              element: { value: file.added_by },
              id: `added_by-${file.id}`,
            },
          ])}
          rowHeaders={[
            { columnData: "File Name", id: "insync-files-name" },
            { columnData: "Status", id: "insync-files-status" },
            { columnData: "Tags", id: "insync-files-tags" },
            { columnData: "Added By", id: "insync-files-added-by" },
          ]}
        />
      )}
    </>
  );
}
