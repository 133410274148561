/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import usePostRequest from "../../hooks/usePostRequest";
import "./FileUploadPreSignedUrl.css";

const FileUploadPreSignedUrl = ({
  field,
  accept = "",
  presignUrlEndpoint,
  completeUploadEndpoint,
  cloudFrontBaseUrl,
  onPresignSuccess,
  onUploadComplete,
}) => {
  const tokens = useSelector((state) => state?.tokens.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const userId = useSelector((state) => state.user.value?.id);
  const fileInputRef = useRef();

  const { sendPostRequest: getPresignedUrls } = usePostRequest(
    `${presignUrlEndpoint}?app_id=${currentApp.id}&user_id=${userId}`,
    tokens
  );

  const { sendPostRequest: completeUpload } = usePostRequest(
    completeUploadEndpoint,
    tokens
  );

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState({});
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (uploading) {
        event.preventDefault();
        event.returnValue = ""; // Standard for most browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploading]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const sanitizeFileName = (fileName) => {
    // Replace problematic characters with safe alternatives
    return fileName.replace(/[,+]/g, "_");
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    const fileNames = files.map((file) => sanitizeFileName(file.name));
    setUploading(true);

    try {
      const presignedResponse = await getPresignedUrls({
        file_names: fileNames,
      });

      const { presigned_urls } = presignedResponse;

      if (onPresignSuccess) onPresignSuccess();

      await Promise.all(
        files.map((file, index) => {
          const { url, url_id, unique_filename } = presigned_urls[index];

          return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", url);

            xhr.upload.onprogress = (event) => {
              if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setProgress((prevProgress) => ({
                  ...prevProgress,
                  [file.name]: percentComplete,
                }));
              }
            };

            xhr.onload = async () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                const cloudfrontUrl = `${cloudFrontBaseUrl}/${unique_filename}`;
                await completeUpload({
                  url_id: url_id,
                  url: cloudfrontUrl,
                });
                resolve();
              } else {
                reject(new Error("Failed to upload file"));
              }
            };

            xhr.onerror = () => reject(new Error("Failed to upload file"));
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.send(file);
          });
        })
      );

      if (onUploadComplete) onUploadComplete();
    } catch (err) {
      console.error("Failed to upload files", err);
      alert("Failed to upload files. Please try again.");
    } finally {
      setUploading(false);
      setProgress({});
    }
  };

  return (
    <div className="form-field">
      <Typography variant="h6">{field.label}</Typography>
      <div className="upload-container">
        <input
          type="file"
          multiple={field.multiple}
          accept={accept}
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <Button
          variant="outlined"
          className="upload-button"
          onClick={handleButtonClick}
          disabled={uploading}
        >
          {uploading ? (
            <CircularProgress size={24} />
          ) : (
            field.title || "Upload Files"
          )}
        </Button>
      </div>
      {uploading && (
        <div className="upload-progress-card">
          {Object.keys(progress).map((fileName) => (
            <div key={fileName} className="progress-item">
              <Typography variant="caption">{fileName}</Typography>
              <LinearProgress
                variant="determinate"
                value={progress[fileName]}
                className="progress-bar"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploadPreSignedUrl;
