import { Stack } from "@mui/material";
import React from "react";

/**
 *
 * @param {React.JSX.Element} children
 * @param {object} style
 * @returns {React.JSX.Element}
 */

export default function SectionTopBar({ children, style }) {
  return (
    <Stack flexDirection={"row"} sx={{ width: "100%",  height: 1, ...style }}>
      {children}
    </Stack>
  );
}
