import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import Dialog from "./Dialog";
import { SubtitleS } from "./Typography";

const TagsCell = ({
  value = [],
  options = [],
  saveButtonClick,
  cancelButtonClick,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(value);

  const handleTagsChange = (event, newValue) => {
    setSelectedTags(newValue);
  };

  const handleSaveTags = () => {
    saveButtonClick(selectedTags);
    setOpen(false);
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setSelectedTags((prevTags) =>
      prevTags.filter((tag) => tag !== tagToDelete)
    );
  };

  return (
    <>
      <Chip
        label={selectedTags.length > 0 ? selectedTags.join(", ") : "Add Tags +"}
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ cursor: "pointer", maxWidth: "200px", whiteSpace: "nowrap" }}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title="Add Tags"
        content={
          <Box sx={{ width: "100%", px: 2 }}>
            <SubtitleS sx={{ mb: 2 }}>
              Start typing to search for and add tags
            </SubtitleS>
            <Autocomplete
              multiple
              freeSolo
              options={options}
              value={selectedTags}
              onChange={handleTagsChange}
              filterSelectedOptions
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add tags"
                  sx={{
                    width: "100% !important", // Use !important to override
                    "& .MuiOutlinedInput-root": {
                      paddingRight: 0,
                      paddingLeft: 0,
                    },
                  }}
                />
              )}
              sx={{ mb: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                maxHeight: "150px",
                overflowY: "auto",
              }}
            >
              {selectedTags.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  onDelete={handleDeleteTag(option)}
                />
              ))}
            </Box>
          </Box>
        }
        actions={
          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="SecondaryButton"
              onClick={() => {
                cancelButtonClick();
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="PrimaryButton" onClick={handleSaveTags}>
              Save
            </Button>
          </Stack>
        }
      />
    </>
  );
};

export default TagsCell;
