import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import SectionTopBar from "../../components/SectionTopBar";
import {
  PageHeader,
  PageSubheader,
  SubtitleS,
} from "../../components/Typography";
import useFetch from "../../hooks/useFetch";
import usePutRequest from "../../hooks/usePutRequest";
import useAutoSave from "../../hooks/useAutoSave";
import TextFieldsLoadingPlaceholder from "../../components/LoadingPlaceholders/TextFieldsLoadingPlaceholder";

export default function Personality() {
  // ===============================================
  // INITIALIZERS
  // ===============================================
  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);

  // Fetch initial data using custom useFetch hook
  const {
    data: configurations,
    loading,
    error,
  } = useFetch(
    `/v1/controls?app_id=${currentApp?.id}&env=sandbox&types=ai_personality`,
    tokens,
    []
  );

  // Initialize the usePutRequest hook for PUT requests
  const { sendPutRequest } = usePutRequest("/v1/controls", tokens);

  // State to manage the AI personality configuration
  const [state, setState] = useState({
    ai_personality: {
      control_definition: "",
      id: null,
      environment: "",
      app_id: null,
    },
  });

  // Calculate the number of sections based on the state
  const numSections = Object.keys(state).length;

  // ===============================================
  // HANDLERS
  // ===============================================
  const saveConfigurations = async () => {
    const updatedConfigurations = [];

    // Prepare the updated configurations
    Object.keys(state).forEach((type) => {
      if (state[type]?.control_definition) {
        const config = {
          control_type: type,
          control_definition: state[type].control_definition,
          environment: "sandbox",
          app_id: currentApp?.id,
        };
        if (state[type].id) {
          config.id = state[type].id;
        }
        updatedConfigurations.push(config);
      }
    });

    // Make PUT request if there are updated configurations
    if (updatedConfigurations?.length > 0) {
      try {
        await sendPutRequest({ controls: updatedConfigurations });
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };

  // Use the useAutoSave hook
  const { handleBlur } = useAutoSave(saveConfigurations, [state]);

  // Handle changes in the form fields
  const changeHandler = (event, type) => {
    const { name, value } = event.target;
    // Update the state with new values
    setState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [name]: value,
      },
    }));
  };

  // ===============================================
  // EFFECTS
  // ===============================================
  // Use effect to set initial configurations when they are fetched
  useEffect(() => {
    if (configurations?.length > 0) {
      configurations.forEach((config) => {
        setState((prevState) => ({
          ...prevState,
          [config.control_type]: {
            control_definition: config.control_definition,
            id: config.id,
            environment: config.environment,
            app_id: config.app_id,
          },
        }));
      });
    }
  }, [configurations]);

  // ===============================================
  // RENDER FUNCTIONS
  // ===============================================
  const renderTextField = (type, label, placeholder, name, rows) => (
    <>
      <TextField
        type="text"
        margin="dense"
        name={name}
        value={state[type][name]}
        onChange={(e) => changeHandler(e, type)}
        // Use handleBlur from the useAutoSave hook
        onBlur={handleBlur}
        sx={{
          "& .MuiOutlinedInput-root": {},
          "& .MuiInputBase-input": {
            padding: "25px 20px 25px 20px",
          },
        }}
        multiline
        minRows={rows}
        maxRows={rows}
        placeholder={placeholder}
        aria-label={name}
      />
      <SubtitleS
        style={{ marginTop: "10px", marginBottom: "10px", color: "#000" }}
      >
        {label}
      </SubtitleS>
    </>
  );

  if (loading) {
    return <TextFieldsLoadingPlaceholder numSections={numSections} />;
  }

  if (error) return <p>Error: {error.message}</p>;

  // ===============================================
  // MAIN RENDER
  // ===============================================
  return (
    <>
      <PageHeader>AI Personality</PageHeader>

      <PageSubheader>Chatbot Personality</PageSubheader>
      <SectionTopBar
        style={{ justifyContent: "space-between", paddingRight: "8px" }}
      />

      {renderTextField(
        "ai_personality",
        "Guidelines for the chatbot on how to interact with customers.",
        `1. E.g. Speak with a warm, friendly tone,\n2. E.g. Make sure to greet customers warmly and offer help in a cheerful manner\n3. E.g. Address the user by their first name`,
        "control_definition",
        18
      )}
    </>
  );
}
