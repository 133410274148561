import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element: Element, ...props }) => {
  const navigate = useNavigate();
  const apps = useSelector((state) => state?.apps?.list || []);
  let params = useParams();

  useEffect(() => {
    let appIndex = apps.findIndex(
      (el) => el.client_name.toLowerCase() === params.app_name.toLowerCase()
    );

    if (appIndex < 0) {
      // navigate("/apps?appNotFound=true");
    }
  }, [apps, params, navigate]);

  return <Element {...props} />;
};

export default PrivateRoute;
