import React from "react";
import { Button, Typography } from "@mui/material";
import usePutRequest from "../hooks/usePutRequest";
import { COLORS } from "../constants/Colors";

const PushToProduction = () => {
  const { sendPutRequest } = usePutRequest("");

  const handlePushToProduction = async () => {
    try {
      const response = await sendPutRequest(/* body */);
      console.log("Pushed to production:", response);
    } catch (err) {
      console.error("Failed to push to production:", err);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        backgroundColor: "#f7f9fc",
        // boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        mt: 2,
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        Ready to go live with the sandbox changes?
      </Typography>
      <Button
        variant="contained"
        onClick={handlePushToProduction}
        style={{
          backgroundColor: COLORS.PRIMARY_COLOR_3,
          color: "#FFFFFF",
        }}
      >
        Push to Production
      </Button>
    </div>
  );
};

export default PushToProduction;
