import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiCall } from "../../../api";
import SectionTopBar from "../../../components/SectionTopBar";
import Table from "../../../components/Table";
import { PageHeader } from "../../../components/Typography";
import OptionMenu from "../../../components/OptionMenu";
import UrlsSidebar from "./UrlsSidebar";

export default function Websites() {
  const user = useSelector((state) => state.user.value);
  const tokens = useSelector((state) => state?.tokens.value);
  const params = useParams();
  const [appId, setAppId] = useState(0);
  const [websites, setWebsites] = useState([]);
  const [saved, setSaved] = useState(0);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);

  const handleAddLinkClick = () => {
    setWebsites((prev) => {
      let newContent = [
        {
          url: "",
          links: "00",
          site_map: "full_website",
          status: "-",
          enabled: "enabled",
          tags: "Add Tags +",
          user: user,
          app_id: appId,
        },
        ...prev,
      ];
      return newContent;
    });
  };

  const handleChange = (index, key, value) => {
    setWebsites((prev) =>
      prev.map((row, i) => (i === index ? { ...row, [key]: value } : row))
    );
  };

  const handleSaveRow = async (index) => {
    let url =
      `/v1/apps/${currentApp.id}/websites/` +
      (websites[index].id !== undefined ? websites[index].id : "") +
      (appId === 0 ? "?app_name=" + params.app_name : "");

    let method = websites[index].id !== undefined ? "Put" : "Post";
    const saveLink = await apiCall(url, {
      method,
      body: { ...websites[index], status: "pending" },
      authTokens: tokens,
    });

    if (saveLink) {
      setSaved((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const getLinks = async () => {
      let linksList = await apiCall(
        `/v1/apps/${currentApp.id}/websites?app_name=` + params.app_name,
        {
          method: "get",
          authTokens: tokens,
        }
      );
      setWebsites(linksList);
      if (linksList.length > 0) {
        setAppId(linksList[0].app_id);
      }
    };

    getLinks();
  }, [saved]);

  return (
    <>
      <PageHeader>Knowledge Pool: Websites</PageHeader>
      <SectionTopBar
        style={{ justifyContent: "space-between", marginTop: "30px" }}
      >
        <TextField
          placeholder="Search"
          margin="dense"
          sx={{
            width: "300px",
            maxHeight: "46px",
            margin: "0px",
            "& input": {
              padding: "10px 20px !important",
            },
          }}
        />
        <Button
          variant="PrimaryButtonBlue"
          onClick={() => {
            handleAddLinkClick();
          }}
        >
          + Add Website
        </Button>
      </SectionTopBar>

      <Table
        customTableStyle={{
          width: "100%",
          marginTop: "30px",
        }}
        customStyle={{
          marginRight: "40px",
          overflowX: "visible",
        }}
        emptyText="Add URLs that your bot will refer to as its knowledge base"
        tableContents={websites?.map((website, websiteIndex) => {
          return [
            {
              style: {
                width: "300px !important",
              },
              id: (website.id ? website.id : "new") + "-url",
              element: {
                type: "textfield",
                value: website.url,
                onChange: (e) =>
                  handleChange(websiteIndex, "url", e.target.value),
                onBlur: () => handleSaveRow(websiteIndex),
                label: "website-url",
                placeholder: "Enter Url",
              },
            },
            {
              id: (website.id ? website.id : "new") + "-sitemap",
              element: {
                type: "selector",
                items: [
                  {
                    title: "Full Website",
                    value: "full_website",
                  },
                  {
                    title: "Just URL",
                    value: "just_url",
                  },
                ],
                value: website.site_map,
                setValue: (val) => {
                  handleChange(websiteIndex, "site_map", val);
                  // handleSaveRow(linkIndex);
                },
                onBlur: () => handleSaveRow(websiteIndex),
              },
            },
            {
              id: (website.id ? website.id : "new") + "-status",
              element: { value: website.status },
            },
            {
              id: (website.id ? website.id : "new") + "-tags",
              element: {
                type: "tags",
                options: ["Development", "Staged", "Production"],
                value: [],
                saveButtonCLick: () => {
                  handleSaveRow(websiteIndex);
                },
                cancelButtonClick: () => {},
              },
            },
            {
              id: (website.id ? website.id : "new") + "-user",
              element: { value: website.user.name },
            },
            {
              id: (website.id ? website.id : "new") + "-options",
              element: {
                value: (
                  <OptionMenu
                    key={(website.id ? website.id : "new") + "-options-button"}
                    options={[
                      {
                        value: "view-links",
                        title: (
                          <UrlsSidebar
                            website={website}
                            currentApp={currentApp}
                            tokens={tokens}
                          />
                        ),
                      },
                      { value: "refresh", title: "Refresh" },
                      { value: "delete", title: "Delete" },
                    ]}
                  />
                ),
              },
            },
          ];
        })}
        rowHeaders={[
          {
            columnData: "Urls",
            id: "urls",
          },

          {
            columnData: "Scan Mode",
            id: "scan-mode",
          },
          {
            columnData: "Status",
            id: "status",
          },
          {
            columnData: "Data Tags",
            id: "data-tags",
          },
          {
            columnData: "Added By",
            id: "added-by",
          },
          {
            columnData: "",
            id: "options-menu",
          },
        ]}
      />
    </>
  );
}
