import { isEmpty } from "lodash";

export const getSubdomainAndMainDomain = (url) => {
  const cleanUrl = url.replace(/(^\w+:|^)\/\//, "");
  const domain = cleanUrl.startsWith("www.") ? cleanUrl.substring(4) : cleanUrl;

  const parts = domain.split(".");

  if (parts.length > 2) {
    const subdomain = parts.slice(0, -2).join("");
    const mainDomain = parts.slice(-2, -1)[0];
    return `${subdomain}${mainDomain}`;
  }

  return parts[0];
};

export const dateFormatter = (dateString, format) => {
  const date = new Date(dateString);
  const formatParts = {
    DD: String(date.getDate()).padStart(2, "0"),
    MM: String(date.getMonth() + 1).padStart(2, "0"),
    YYYY: date.getFullYear(),
    HH: String(date.getHours() % 12 || 12).padStart(2, "0"),
    mm: String(date.getMinutes()).padStart(2, "0"),
    ss: String(date.getSeconds()).padStart(2, "0"),
    AMPM: date.getHours() >= 12 ? "PM" : "AM",
  };
  return format.replace(
    /DD|MM|YYYY|HH|mm|ss|AMPM/g,
    (match) => formatParts[match]
  );
};

export const rolesSelectorBasedOnHierarchy = (role, rolesArray) => {
  const currentRoleIndex = rolesArray.findIndex((r) => r.value === role);

  return rolesArray.filter((r, index) => index <= currentRoleIndex);
};

export const convertUnderscoreToSpaces = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getStringParamsFromObject = (params) => {
  return Object.keys(params)
    .filter((key) => typeof params[key] === "number" || !isEmpty(params[key]))
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");
};
