export const getAccessToken = () => {
  return localStorage.getItem("authToken");
};

export const setAccessToken = (token) => {
  localStorage.setItem("authToken", token);
};

export const deleteAccessToken = () => {
  localStorage.removeItem("authToken");
};

export const isAuthenticated = async () => {
  let token = getAccessToken();
  if (!token) {
   token = await fetchUserToken()
  }
  return !!token;
};

export const fetchUserToken = async () => {
  try {
    const apiResponse = await fetch(
      process.env.REACT_APP_DEV_BACKOFFICE_URL+ "/generate_jwt_token",
      {
        method: "POST",
        credentials: "include",
      }
    );

    const result = await apiResponse.json();
    // console.log(result);

    const { token } = result;

    if (token) {
      setAccessToken(token);
    }

    return token;
  } catch (error) {
    console.error(error);
    return null;
  }
};
