import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  selectedApp: null,
};

const appsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {
    // Action to save the list of apps
    saveApps: (state, action) => {
      localStorage.setItem("apps", JSON.stringify(action.payload));
      state.list = action.payload;
    },
    // Action to select an app
    selectApp: (state, action) => {
      state.selectedApp = action.payload;
      localStorage.setItem("selectedApp", JSON.stringify(action.payload));
    },

    initializeApp: (state, action) => {
      state.selectedApp = action.payload;
    },
  },
});

export const { saveApps, selectApp, initializeApp } = appsSlice.actions;
export default appsSlice.reducer;
