import React from "react";
import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";

const Dropdown = ({ label, options, value, onChange }) => {
  return (
    <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: 2 }}>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        value={value}
        onChange={onChange}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
