/**
 * Copies the given text to the clipboard and shows an alert.
 *
 * @param {string} text - The text to copy to the clipboard.
 */
export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    console.error("Clipboard API not supported");
    return;
  }

  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert("Text copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
      alert("Failed to copy text.");
    });
}

export default copyToClipboard;
