import { useState } from "react";
import { apiCall } from "../api";

/**
 * Custom hook to handle PUT requests.
 *
 * @param {string} url - The API endpoint URL where the PUT request will be sent.
 * @param {object} tokens - The authentication tokens for accessing the API.
 * @returns {object} - An object containing the response data, loading state, error state,
 * and a function to send the PUT request.
 * @property {any} data - The response data from the API.
 * @property {boolean} loading - Whether the PUT request is currently in progress.
 * @property {Error|null} error - Any error that occurred during the PUT request.
 * @property {function} sendPutRequest - Function to send the PUT request.
 */
const usePutRequest = (url, tokens) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  /**
   * Function to send a PUT request to the given URL.
   *
   * @param {object} body - The request body to be sent with the PUT request.
   * @returns {Promise<any>} - The response data from the API.
   * @throws {Error} - Throws an error if the PUT request fails.
   */
  const sendPutRequest = async (body, urlParam = "") => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiCall(url + urlParam, {
        method: "PUT",
        authTokens: tokens,
        body,
      });

      setData(response.response || []);
      setLoading(false);
      return response.response;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw err;
    }
  };

  return { data, loading, error, sendPutRequest };
};

export default usePutRequest;
