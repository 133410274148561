export const BASIC_SETTINGS_FIELDS = {
  "Chat Header": [
    {
      type: "image",
      label: "Brand Logo for Header*",
      name: "brandLogo",
      internal_name: "brand_logo",
      defaultValue: "",
      description: "Brand logo displayed in the chat header.",
    },
    {
      type: "text",
      label: "Header Title Text",
      name: "chatHeaderTitle",
      internal_name: "chat_header_title",
      placeholder: "Eg. Chat Assistant",
      defaultValue: "Chat Assistant",
      description: "Text displayed in the chat header.",
    },
    {
      type: "color",
      label: "Background Gradient Left Color*",
      name: "headerBackgroundGradientLeftColor",
      internal_name: "header_bg_gradient_left_color",
      defaultValue: "#0795e6",
      description: "Left gradient color of the header background.",
    },
    {
      type: "color",
      label: "Background Gradient Right Color*",
      name: "headerBackgroundGradientRightColor",
      internal_name: "header_bg_gradient_right_color",
      defaultValue: "#00d4ff",
      description: "Right gradient color of the header background.",
    },
    {
      type: "color",
      label: "Header Title Color*",
      name: "headerTitleColor",
      internal_name: "header_title_color",
      defaultValue: "#ffffff",
      description: "Color of the header title text.",
    },
  ],
  "Chat Body": [
    {
      type: "text",
      label: "System Display Name",
      name: "chatSystemDisplayName",
      internal_name: "chat_system_display_name",
      placeholder: "Eg. Chat Assistant",
      defaultValue: "Chat Assistant",
      description: "Name displayed for system messages.",
    },
    {
      type: "text",
      label: "User Display Name",
      name: "chatUserDisplayName",
      internal_name: "chat_user_display_name",
      placeholder: "Eg. Me",
      defaultValue: "Me",
      description: "Name displayed for user messages.",
    },
  ],
  "Chat Button": [
    {
      type: "image",
      label: "Chat Button Icon*",
      name: "chatButtonIcon",
      internal_name: "chat_button_icon",
      defaultValue: "",
      description: "Chat Button Icon.",
    },
  ],
};

export const ADVANCED_SETTINGS_FIELDS = {
  "Chat Header": [
    {
      type: "number",
      label: "Header Text Font Size*",
      name: "headerTextFontSize",
      internal_name: "header_title_font_size",
      defaultValue: 16,
      description: "Font size of the header text.",
    },
    {
      type: "text",
      label: "Header Box Shadow",
      name: "headerBoxShadow",
      internal_name: "header_box_shadow",
      placeholder: "rgb(8 8 8 / 35%) 0px -11px 28px",
      defaultValue: "rgb(8 8 8 / 35%) 0px -11px 28px",
      description: "Box shadow of the chat header.",
    },
    {
      type: "text",
      label: "Trigger Points SDK Logo name (Specify if for trigger points)",
      name: "sdkLogoName",
      internal_name: "sdk_logo_name",
      defaultValue: "",
      description: "SDK logo name for trigger points.",
    },
    {
      type: "number",
      label: "Header Text Font Weight*",
      name: "headerTextFontWeight",
      internal_name: "header_title_font_weight",
      defaultValue: 600,
      description: "Font weight of the header text.",
    },
    {
      type: "text",
      label: "Header Notice Text",
      name: "headerNoticeText",
      internal_name: "header_notice_text",
      placeholder: "",
      defaultValue: "",
      description: "Notice text displayed in the header.",
    },
    {
      type: "select",
      label: "Cross/Minimize Icon",
      name: "chatHeaderCrossIcon",
      internal_name: "chat_header_cross_icon",
      options: ["minimizeIcon", "crossIcon"],
      placeholder: "Eg. minimizeIcon/crossIcon",
      defaultValue: "minimizeIcon",
      description: "Icon to minimize or close the chat window.",
    },
  ],
  "Chat Body": [
    {
      type: "color",
      label: "Background Color",
      name: "chatBodyBackgroundColor",
      internal_name: "chat_body_background_color",
      defaultValue: "#FFFFFF",
      description: "Background color of the chat body.",
    },
    {
      type: "number",
      label: "Text Font Size",
      name: "textMsgFontSize",
      internal_name: "text_msg_font_size",
      unit: "px",
      defaultValue: 15,
      description: "Font size of the text messages.",
    },
    {
      type: "boolean",
      label: "Show Queue Position",
      name: "showQueuePosition",
      internal_name: "show_queue_position",
      defaultValue: false,
      description: "Whether to show the user's position in the queue.",
    },
    {
      type: "boolean",
      label: "Show Agent Typing Indicator",
      name: "showAgentTypingIndicator",
      internal_name: "show_agent_typing_indicator",
      defaultValue: false,
      description: "Whether to show typing indicator for agents.",
    },
  ],
  "Chat Footer": [
    {
      type: "color",
      label: "Background Gradient Left Color",
      name: "footerBgGradientLeftColor",
      internal_name: "footer_bg_gradient_left_color",
      placeholder: "Eg. #844FD1",
      defaultValue: "#FFFFFF",
      description: "Left gradient color of the footer background.",
    },
    {
      type: "color",
      label: "Background Gradient Right Color",
      name: "footerBgGradientRightColor",
      internal_name: "footer_bg_gradient_right_color",
      placeholder: "Eg. #844FD1",
      defaultValue: "#FFFFFF",
      description: "Right gradient color of the footer background.",
    },
    {
      type: "text",
      label: "Ghost Text for input box",
      name: "inputFieldGhostText",
      internal_name: "input_field_ghost_text",
      placeholder: "Eg. Type Here...",
      defaultValue: "Type here...",
      description: "Placeholder text for the input box.",
    },
    {
      type: "text",
      label: "Ghost Text for disabled input box",
      name: "disabledGhostText",
      internal_name: "disabled_ghost_text",
      placeholder: "Eg. Select from the options above.",
      defaultValue: "Select from the options above.",
      description: "Placeholder text for the disabled input box.",
    },
    {
      type: "color",
      label: "Chat Input Box Border Color",
      name: "chatInputBorderColor",
      internal_name: "chat_input_border_color",
      placeholder: "Eg. #844FD1",
      defaultValue: "#000000",
      description: "Border color of the chat input box.",
    },
    {
      type: "boolean",
      label: "Show Poweredby Footer",
      name: "showPoweredbyFooter",
      internal_name: "show_poweredby_footer",
      defaultValue: true,
      description: "Whether to show the 'Powered by' footer.",
    },
    {
      type: "text",
      label: "Footer Notice Text",
      name: "footerNoticeText",
      internal_name: "footer_notice_text",
      placeholder:
        "Eg. Powered by AI; responses may not always be accurate and should be verified.",
      defaultValue: "",
      description: "Notice text displayed in the footer.",
    },
    {
      type: "text",
      label:
        "Show Top Border Line of input box (with send icon inside rounded input box)",
      name: "inputBorderTop",
      internal_name: "input_border_top",
      defaultValue: "none",
      description: "Top border line of the input box.",
    },
    {
      type: "color",
      label: "Send Icon Circle Fill Color",
      name: "userSendIconCircleFillColor",
      internal_name: "user_send_icon_circle_fill_color",
      placeholder: "Eg. #844FD1",
      defaultValue: "#FFFFFF",
      description: "Fill color of the send icon circle.",
    },
    {
      type: "color",
      label: "Send Icon Circle Border Color",
      name: "userSendIconCircleBorderColor",
      internal_name: "user_send_icon_circle_border_color",
      placeholder: "Eg. #844FD1",
      defaultValue: "#000000",
      description: "Border color of the send icon circle.",
    },
    {
      type: "color",
      label: "Send Icon Arrow Color",
      name: "userSendIconArrowColor",
      internal_name: "user_send_icon_arrow_color",
      placeholder: "Eg. #844FD1",
      defaultValue: "#000000",
      description: "Color of the send icon arrow.",
    },
    {
      type: "color",
      label: "Disabled Send Icon Circle Fill Color",
      name: "userSendIconDisabledCircleFillColor",
      internal_name: "user_send_icon_disabled_circle_fill_color",
      placeholder: "Eg. #FFFFFF",
      defaultValue: "#FFFFFF",
      description: "Fill color of the disabled send icon circle.",
    },
    {
      type: "color",
      label: "Disabled Send Icon Circle Border Color",
      name: "userSendIconDisabledCircleBorderColor",
      internal_name: "user_send_icon_disabled_circle_border_color",
      placeholder: "Eg. #844FD1",
      defaultValue: "#919191",
      description: "Border color of the disabled send icon circle.",
    },
    {
      type: "color",
      label: "Disabled Send Icon Arrow Color",
      name: "userSendIconDisabledArrowColor",
      internal_name: "user_send_icon_disabled_arrow_color",
      placeholder: "Eg. #844FD1",
      defaultValue: "#919191",
      description: "Color of the disabled send icon arrow.",
    },
    {
      type: "color",
      label: "Send Icon Color",
      name: "userSendIconColor",
      internal_name: "user_send_icon_color",
      placeholder: "Leave empty for default.",
      defaultValue: "",
      description: "Color of the send icon.",
    },
  ],
  "Welcome Screen": [
    {
      type: "text",
      label: "Client Display Name",
      name: "clientDisplayName",
      internal_name: "client_display_name",
      placeholder: "Eg. Floor & Decor",
      defaultValue: "",
      description: "Display name of the client.",
    },
    {
      type: "text",
      label: "Welcome Screen Text",
      name: "preChatWelcomeScreenText",
      internal_name: "pre_chat_welcome_screen_text",
      placeholder: "Eg. Floor & Decor",
      defaultValue: "I can help you with all your questions!",
      description: "Text displayed on the welcome screen.",
    },
    {
      type: "boolean",
      label: "Welcome Screen Business Card",
      name: "preChatWelcomeScreenEnabled",
      internal_name: "pre_chat_welcome_screen_enabled",
      defaultValue: false,
      description: "Whether the welcome screen business card is enabled.",
    },
  ],
  "Chat Icon Style": [
    {
      type: "number",
      label: "Chat Icon Position from Left",
      name: "chatIconLeft",
      internal_name: "chat_icon_left",
      unit: "px",
      placeholder: "Eg. 25",
      defaultValue: 25,
      description: "Position of the chat icon from the left of the screen.",
    },
    {
      type: "number",
      label: "Chat Icon Position from Right",
      name: "chatIconRight",
      internal_name: "chat_icon_right",
      unit: "px",
      placeholder: "Eg. 25",
      defaultValue: 25,
      description: "Position of the chat icon from the right of the screen.",
    },
    {
      type: "number",
      label: "Chat Icon Position from Bottom",
      name: "chatIconBottom",
      internal_name: "chat_icon_bottom",
      unit: "px",
      placeholder: "Eg. 0",
      defaultValue: 0,
      description: "Position of the chat icon from the bottom of the screen.",
    },
  ],
  "Chat Window": [
    {
      type: "select",
      label: "Window Style",
      name: "windowStyle",
      internal_name: "windowStyle",
      options: ["regular", "wide", "tall"],
      placeholder: "Eg. regular/wide/tall",
      defaultValue: "regular",
      description: "Size of the chat window",
    },
    {
      type: "text",
      label: "Window Position from Right",
      name: "right",
      internal_name: "right",
      unit: "px",
      placeholder: "Eg. 25",
      defaultValue: 25,
      description: "Window Position from Right of the Screen",
    },
    {
      type: "text",
      label: "Window Position from Left",
      name: "left",
      internal_name: "left",
      unit: "px",
      placeholder: "Eg. 25",
      defaultValue: "auto",
      description: "Window Position from Left of the Screen",
    },
    {
      type: "text",
      label: "Window Position from Bottom",
      name: "bottom",
      internal_name: "bottom",
      unit: "px",
      placeholder: "Eg. 25",
      defaultValue: 25,
      description: "Window Position from Bottom of the Screen",
    },
    {
      type: "number",
      label: "Chatbox Border Radius",
      name: "chatBoxBorderRadius",
      internal_name: "chatBoxBorderRadius",
      unit: "px",
      placeholder: "Eg. 8",
      defaultValue: 8,
      description: "Chatbox Border Radius",
    },
    {
      type: "text",
      label: "Chatbox Outer Shadow",
      name: "chatBoxShadow",
      internal_name: "chatBoxShadow",
      placeholder: "rgba(8, 8, 8, 0.35) 20px 20px 25px",
      defaultValue: "rgba(8, 8, 8, 0.35) 20px 20px 25px",
      description: "Chatbox Outer Shadow",
    },
    {
      type: "text",
      label: "Chatbox Transition",
      name: "chat_box_transition",
      internal_name: "chatBoxTransition",
      placeholder: "all 0.5s",
      defaultValue: "all 0.5s",
      description: "Chatbox Transition while initial load",
    },
    {
      type: "boolean",
      label: "Resize Feature Enabled",
      name: "resizeFeatureEnabled",
      internal_name: "resize_feature_enabled",
      defaultValue: false,
      description: "Resize Feature Enabled",
    },
    {
      type: "number",
      label: "Resize Window Height (Resize Feature must be Enabled)",
      name: "resizeWindowHeight",
      internal_name: "resize_window_height",
      placeholder: "600",
      unit: "px",
      defaultValue: null,
      description: "Resize Window Height (Resize Feature must be Enabled)",
    },
    {
      type: "number",
      label: "Resize Window Width (Resize Feature must be Enabled)",
      name: "resizeWindowWidth",
      internal_name: "resize_window_width",
      placeholder: "500",
      unit: "px",
      defaultValue: null,
      description: "Resize Window Width (Resize Feature must be Enabled)",
    },
  ],
  "User Response": [
    {
      type: "color",
      label: "User Text Color",
      name: "userTextMsgColor",
      internal_name: "user_text_msg_color",
      defaultValue: "#ffffff",
      description: "Color of the user's text messages",
    },
    {
      type: "color",
      label: "User Text Background Color",
      name: "userTextMsgBgColor",
      internal_name: "user_text_msg_bg_color",
      defaultValue: "#198fff",
      description: "Background color of the user's text messages",
    },
  ],
  "AI Response": [
    {
      type: "color",
      label: "System Text Color",
      name: "systemTextMsgColor",
      internal_name: "system_text_msg_color",
      defaultValue: "#000000",
      description: "Color of the system's text messages",
    },
    {
      type: "color",
      label: "System Text Background Color",
      name: "systemTextMsgBgColor",
      internal_name: "system_text_msg_bg_color",
      defaultValue: "#f3f3f3",
      description: "Background color of the system's text messages",
    },
  ],
  "AI CTAs (Buttons, Bubbles)": [
    {
      type: "color",
      label: "Button Text Color",
      name: "buttonTextColor",
      internal_name: "button_text_color",
      placeholder: "Leave empty for default.",
      defaultValue: "",
      description: "",
    },
    {
      type: "color",
      label: "Button Background Color",
      name: "buttonBackgroundColor",
      internal_name: "button_background_color",
      placeholder: "Leave empty for default.",
      defaultValue: "",
      description: "",
    },
    {
      type: "color",
      label: "Button Hover Text Color",
      name: "buttonHoverTextColor",
      internal_name: "button_hover_text_color",
      placeholder: "Leave empty for default.",
      defaultValue: "",
      description: "",
    },
    {
      type: "color",
      label: "Button Hover Background Color",
      name: "buttonHoverBackgroundColor",
      internal_name: "button_hover_background_color",
      placeholder: "Leave empty for default.",
      defaultValue: "",
      description: "",
    },
    {
      type: "text",
      label: "Button Text Align",
      name: "buttonTextAlignment",
      internal_name: "button_text_alignment",
      placeholder: "Leave empty for default.",
      defaultValue: "center",
      description: "",
    },
    {
      type: "text",
      label: "Button/List Border",
      name: "buttonBorder",
      internal_name: "button_border",
      defaultValue: "",
      description: "",
    },
    {
      type: "number",
      label: "Quick-Reply/Bubble Border Radius",
      name: "quickReplyBubbleRadius",
      internal_name: "quick_reply_bubble_radius",
      unit: "px",
      placeholder: "Eg. 8",
      defaultValue: 5,
      description: "Quick-Reply/Bubble Border Radius",
    },
    {
      type: "color",
      label: "Bubble Text Color",
      name: "quickReplyBubbleTextColor",
      internal_name: "quick_reply_bubble_text_color",
      placeholder: "Leave empty for default.",
      defaultValue: "",
      description: "",
    },
    {
      type: "color",
      label: "Bubble Border Color",
      name: "quickReplyBubbleBorderColor",
      internal_name: "quick_reply_bubble_border_color",
      placeholder: "Leave empty for default.",
      defaultValue: "",
      description: "",
    },
    {
      type: "color",
      label: "Bubble Hover BG Color",
      name: "quickReplyBubbleHoverBgColor",
      internal_name: "quick_reply_bubble_hover_bg_color",
      placeholder: "Leave empty for default.",
      defaultValue: "",
      description: "",
    },
    {
      type: "text",
      label: "Bubbles Alignment",
      name: "quickReplyBubblesAlignment",
      internal_name: "quick_reply_bubbles_alignment",
      placeholder: "Leave empty for default.",
      defaultValue: "center",
      description: "",
    },
    {
      type: "number",
      label: "Bubble Text Font Size",
      name: "quickReplyBubbleFontSize",
      internal_name: "quick_reply_bubble_font_size",
      unit: "px",
      placeholder: "Eg. 16",
      defaultValue: 13,
      description: "Bubble Text Font Size",
    },
  ],
  Others: [
    {
      type: "text",
      label: "Text Font Family",
      name: "textFontFamily",
      internal_name: "text_font_family",
      placeholder: "",
      defaultValue: "'Helvetica neue', 'Arial', 'Lucida Grande', 'sans-serif'",
      description: "",
    },
    {
      type: "text",
      label: "Container/List Border/Separator",
      name: "containerBorder",
      internal_name: "container_border",
      defaultValue: "",
      description: "",
    },
    {
      type: "number",
      label: "Button Text Font Weight",
      name: "btnTextFontWeight",
      internal_name: "btn_text_font_weight",
      placeholder: "Eg. 500",
      defaultValue: 500,
      description: "Header Text Font Weight",
    },
    {
      type: "number",
      label: "Quick-Reply/Bubble Inside Padding",
      name: "quickReplyPadding",
      internal_name: "quick_reply_padding",
      unit: "px",
      placeholder: "Eg. 6",
      defaultValue: 6,
      description: "Quick-Reply/Bubble Inside Padding",
    },
    {
      type: "number",
      label: "Space between Quick-Replies/Bubbles",
      name: "quickReplyMarginLeft",
      internal_name: "quick_reply_margin_left",
      unit: "px",
      placeholder: "Eg. 5",
      defaultValue: 5,
      description: "Space between Quick-Replies/Bubbles",
    },
    {
      type: "boolean",
      label: "Show System Messages (Eg. Chat ended)",
      name: "showSystemMessages",
      internal_name: "show_system_messages",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Show System Icon with every bot message",
      name: "showSystemIcon",
      internal_name: "show_system_icon",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Show User Icon with every user message",
      name: "showUserIcon",
      internal_name: "show_user_icon",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Show toggle icons (regular, tall & wide)",
      name: "showToggleWindowIcons",
      internal_name: "show_toggle_window_icons",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Handle SDK loading errors",
      name: "handleLoadError",
      internal_name: "handle_load_error",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Enable Confirmation Dialog before opening any external URL",
      name: "enableExtLinkPopoutConfirmation",
      internal_name: "enable_ext_link_popout_confirmation",
      defaultValue: false,
      description: "Enable Confirmation Dialog before opening any external URL",
    },
    {
      type: "text",
      label: "Confirmation Dialog Text before opening any external URL",
      name: "linkPopoutConfirmationText",
      internal_name: "link_popout_confirmation_text",
      defaultValue: "",
      description: "Confirmation Dialog Text before opening any external URL",
    },
    {
      type: "boolean",
      label: "File Attachment Feature",
      name: "fileAttachmentEnabled",
      internal_name: "file_attachment_enabled",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "End Chat Feature",
      name: "endChatFeatureEnabled",
      internal_name: "end_chat_feature_enabled",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Sound Feature (On every new bot message)",
      name: "soundFeatureEnabled",
      internal_name: "sound_feature_enabled",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Enable CSAT for VA Sessions",
      name: "enableCsatForVaSessions",
      internal_name: "enable_csat_for_va_sessions",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Enable CSAT for Agent Sessions",
      name: "enableCsatForAgentSessions",
      internal_name: "enable_csat_for_agent_sessions",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Enable Pre-Chat form with name before starting VA session",
      name: "showPrechatFormUserName",
      internal_name: "show_prechat_form_user_name",
      defaultValue: false,
      description: "",
    },
    {
      type: "boolean",
      label: "Enable Pre-Chat form with email before starting VA session",
      name: "showPrechatFormUserEmail",
      internal_name: "show_prechat_form_user_email",
      defaultValue: false,
      description: "",
    },
    {
      type: "number",
      label: "Request to be timeout when stuck for long",
      name: "requestTimeout",
      internal_name: "request_timeout",
      defaultValue: 10,
      description: "",
    },
    {
      type: "select",
      label: "Vertical List (Eg. FAQs list)",
      name: "listStyle",
      internal_name: "list_style",
      options: ["default", "custom"],
      placeholder: "Eg. default/custom",
      defaultValue: "default",
      description: "Vertical list styling",
    },
    {
      type: "select",
      label: "Button Style",
      name: "buttonStyle",
      internal_name: "button_style",
      options: ["default", "rounded"],
      placeholder: "Eg. default/rounded",
      defaultValue: "default",
      description: "Button styles (pointed or rounded corners)",
    },
    {
      type: "select",
      label: "Card Style (Rounded Card vs pointed cards)",
      name: "cardStyle",
      internal_name: "card_style",
      options: ["default", "custom", "customDefault"],
      placeholder: "Eg. default/custom",
      defaultValue: "default",
      description: "Button styles (pointed or rounded corners)",
    },
  ],
  "Preview Button": [
    {
      type: "text",
      label: "Preview Message",
      name: "previewMessageForChatPreviewModule",
      internal_name: "preview_message_for_chat_preview_module",
      placeholder:
        "Eg. May I help you with any questions on this page or <TITLE>?",
      defaultValue: "",
      description: "Preview message for new chat icon experience",
    },
    {
      type: "text",
      label: "Input Placeholder",
      name: "previewButtonInputPlaceholder",
      internal_name: "preview_button_input_placeholder",
      placeholder: "Eg. Type your message...",
      defaultValue: "Type your message...",
      description: "Input Placeholder for Preview Module",
    },
    {
      type: "color",
      label: "Background Color for Preview Button Header",
      name: "previewHeaderBackgroundColor",
      internal_name: "preview_header_background_color",
      placeholder: "Add #FED92F for default.",
      defaultValue: "#FFFFFF",
      description: "",
    },
    {
      type: "color",
      label: "Text Color for Preview Button Header",
      name: "previewHeaderTextColor",
      internal_name: "preview_header_text_color",
      placeholder: "Add #FED92F for default.",
      defaultValue: "#000000",
      description: "",
    },
  ],
  "Upload Feature": [
    {
      type: "text",
      label: "Allowed file formats for Upload Feature",
      name: "uploadFileAllowedFormats",
      internal_name: "upload_file_allowed_formats",
      placeholder: "Eg. jpg,png",
      defaultValue: "",
      description: "Allowed formats for Upload Feature",
    },
    {
      type: "number",
      label: "Max File Size [Default: 5]",
      name: "uploadFileMaxSizeInMb",
      internal_name: "upload_file_max_size_in_mb",
      placeholder: "Eg. 5",
      defaultValue: null,
      description: "Max File Size for Upload Feature",
    },
  ],
  "Star Based CSAT": [
    {
      type: "color",
      label: "Color for Selected Star",
      name: "csatStarRatingSelectedColor",
      internal_name: "csat_star_rating_selected_color",
      placeholder: "Add #FED92F for default.",
      defaultValue: "",
      description: "",
    },
    {
      type: "color",
      label: "Color for Unselected Star",
      name: "csatStarRatingUnselectedColor",
      internal_name: "csat_star_rating_unselected_color",
      placeholder: "Add #D8D8D8 for default.",
      defaultValue: "",
      description: "",
    },
  ],
};
