import React from "react";
import { GENERIC_LABEL_FONT_SIZE } from "../constants/Constants";

export const LabelM = ({ children, style }) => {
  return (
    <h6
      style={{
        fontSize: GENERIC_LABEL_FONT_SIZE,
        fontWeight: "500",
        lineHeight: "25.37px",
        textAlign: "center",
        margin: 0,
        ...style,
      }}
    >
      {children}
    </h6>
  );
};

export const PageHeader = ({ children, style }) => {
  return (
    <h2
      style={{
        fontSize: "36px",
        fontWeight: "700",
        lineHeight: "41.51px",
        textAlign: "left",
        marginBottom: "10px",
        marginTop: "12px",
        ...style,
      }}
    >
      {children}
    </h2>
  );
};

export const PageSubheader = ({ children, style, onClick }) => {
  return (
    <h3
      style={{
        fontSize: "28px",
        fontWeight: "600",
        lineHeight: "32.28px",
        textAlign: "left",
        marginBottom: "10px",
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </h3>
  );
};

export const SubtitleM = ({ children, style }) => {
  return (
    <p
      style={{
        fontSize: "24px",
        fontWeight: "400",
        lineHeight: "27.67px",
        textAlign: "left",
        color: "#0D2855",
        margin: 0,
        ...style,
      }}
    >
      {children}
    </p>
  );
};

export const SubtitleS = ({ children, style }) => {
  return (
    <p
      style={{
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "16.14px",
        textAlign: "center",
        color: "#9DA6B6",
        ...style,
      }}
    >
      {children}
    </p>
  );
};

export const CaptionM = ({ children, style }) => {
  return (
    <p
      style={{
        fontSize: "18px",
        fontWeight: "300",
        lineHeight: "20.75px",
        textAlign: "center",
        color: "#9DA6B6",
        margin: 0,
        ...style,
      }}
    >
      {children}
    </p>
  );
};

export const CaptionS = ({ children, style }) => {
  return (
    <p
      style={{
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "18.45px",
        textAlign: "center",
        color: "#9DA6B6",
        margin: 0,
        ...style,
      }}
    >
      {children}
    </p>
  );
};

export const InfoText = ({ children, style }) => {
  return (
    <p
      style={{
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "16.14px",
        textAlign: "center",
        color: "#9DA6B6",
        ...style,
      }}
    >
      {children}
    </p>
  );
};
