import { useCallback } from "react";

/**
 * Custom hook to handle auto-saving on the blur event.
 * @param {function} saveFunction - The function to call to save the state.
 * @param {array} dependencies - The dependency array for the save function.
 * @returns {object} - An object containing the handleBlur event handler for inputs.
 */
const useAutoSave = (saveFunction, dependencies = []) => {
  // Memoize the save function to avoid unnecessary re-renders
  const save = useCallback(saveFunction, dependencies);

  // Event handler for the blur event
  const handleBlur = () => {
    save();
  };

  return { handleBlur };
};

export default useAutoSave;
