import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectApp } from "../slices/AppsSlice";
import { InsyncIcon } from "./utils/InsyncIconsUtil";

export default function TopBar(props) {
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: "999999",
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="home"
              sx={{ mr: 2 }}
              onClick={(e) => {
                dispatch(selectApp(null));
                navigate("/");
              }}
            >
              <InsyncIcon />
            </IconButton>
            {currentApp && (
              <>
                <span
                  style={{
                    fontFamily: "THICCCBOI",
                    fontSize: "22px",
                    fontWeight: "600",
                    lineHeight: "43px",
                    textAlign: "left",
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontFamily: "THICCCBOI",
                    fontSize: "22px",
                    fontWeight: "600",
                    lineHeight: "43px",
                    textAlign: "left",
                    marginLeft: "18px",
                  }}
                >
                  {currentApp.client_name}
                </span>
              </>
            )}
          </Stack>
          <IconButton color="inherit">
            <Avatar />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
