import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import SectionTopBar from "../../components/SectionTopBar";
import {
  PageHeader,
  PageSubheader,
  SubtitleM,
  SubtitleS,
} from "../../components/Typography";
import useFetch from "../../hooks/useFetch";
import usePutRequest from "../../hooks/usePutRequest";
import useAutoSave from "../../hooks/useAutoSave";
import TextFieldsLoadingPlaceholder from "../../components/LoadingPlaceholders/TextFieldsLoadingPlaceholder";

export default function Controls() {
  // ===============================================
  // INITIALIZERS
  // ===============================================
  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);

  // Fetch initial data using custom useFetch hook
  const {
    data: configurations,
    loading,
    error,
  } = useFetch(
    `/v1/controls?app_id=${currentApp?.id}&env=sandbox&types=sensitive_topics,no_go_topics`,
    tokens,
    []
  );

  // Initialize the usePutRequest hook for PUT requests
  const { sendPutRequest } = usePutRequest("/v1/controls", tokens);

  // State to manage the sensitive topics and no-go topics
  const [state, setState] = useState({
    sensitive_topics: {
      control_definition: "",
      disclaimer: "",
      id: null,
      environment: "",
      app_id: null,
    },
    no_go_topics: {
      control_definition: "",
      disclaimer: "",
      id: null,
      environment: "",
      app_id: null,
    },
  });

  const [controlsUpdated, setControlsUpdated] = useState({
    sensitive_topics: false,
    no_go_topics: false,
  });

  // Calculate the number of sections based on the state
  const numSections = Object.keys(state).length;

  // ===============================================
  // HANDLERS
  // ===============================================

  // Function to save configurations
  const saveConfigurations = async () => {
    const updatedControls = [];

    // Prepare the updated configurations
    Object.keys(controlsUpdated).forEach((type) => {
      if (controlsUpdated[type]) {
        const config = {
          control_type: type,
          control_definition: state[type].control_definition,
          disclaimer: state[type].disclaimer,
          environment: "sandbox",
          app_id: currentApp?.id,
        };
        if (state[type].id) {
          config.id = state[type].id;
        }
        updatedControls.push(config);
      }
    });

    // Make PUT request if there are updated configurations
    if (updatedControls.length > 0) {
      try {
        const response = await sendPutRequest({
          controls: updatedControls,
        });

        // Update the local state with the response
        const updatedConfigs = response || [];

        updatedConfigs.forEach((config) => {
          setState((prevState) => ({
            ...prevState,
            [config.control_type]: {
              control_definition: config.control_definition,
              disclaimer: config.disclaimer,
              id: config.id,
              environment: config.environment,
              app_id: config.app_id,
            },
          }));
        });

        setControlsUpdated({
          sensitive_topics: false,
          no_go_topics: false,
        });
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };

  // Use the useAutoSave hook
  const { handleBlur } = useAutoSave(saveConfigurations, [state]);

  // Handle changes in the form fields
  const changeHandler = (event, type) => {
    const { name, value } = event.target;

    // Update local state with new values
    setState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [name]: value,
      },
    }));

    setControlsUpdated((prevState) => ({
      ...prevState,
      [type]: true,
    }));
  };

  // ===============================================
  // EFFECTS
  // ===============================================

  // Use effect to set initial configurations when they are fetched
  useEffect(() => {
    if (configurations.length > 0) {
      configurations.forEach((config) => {
        setState((prevState) => ({
          ...prevState,
          [config.control_type]: {
            control_definition: config.control_definition,
            disclaimer: config.disclaimer,
            id: config.id,
            environment: config.environment,
            app_id: config.app_id,
          },
        }));
      });
    }
  }, [configurations]);

  // ===============================================
  // RENDER FUNCTIONS
  // ===============================================

  // Render a text field with the given parameters
  const renderTextField = (type, label, placeholder, name, subText, rows) => (
    <>
      <SubtitleM style={{ marginTop: "10px", marginBottom: "10px" }}>
        {label}
      </SubtitleM>
      <TextField
        type="text"
        margin="dense"
        name={name}
        value={state[type][name]}
        onChange={(e) => changeHandler(e, type)}
        // Use handleBlur from the useAutoSave hook
        onBlur={handleBlur}
        sx={{
          "& .MuiOutlinedInput-root": {},
          "& .MuiInputBase-input": {
            padding: "25px 20px 25px 20px",
          },
        }}
        multiline
        minRows={rows}
        maxRows={rows}
        placeholder={placeholder}
        aria-label={name}
      />
      <SubtitleS
        style={{ marginTop: "10px", marginBottom: "10px", color: "#000" }}
      >
        {subText}
      </SubtitleS>
    </>
  );

  // Display loading state while fetching data
  if (loading) {
    return <TextFieldsLoadingPlaceholder numSections={numSections} />;
  }

  // Display error message if there was an error
  if (error) return <p>Error: {error.message}</p>;

  // ===============================================
  // MAIN RENDER
  // ===============================================
  return (
    <>
      <PageHeader>Controls</PageHeader>

      {/* =================================================
      // SENSITIVE TOPICS SECTION
      ================================================= */}
      <PageSubheader>Sensitive Topics</PageSubheader>
      <SectionTopBar
        style={{ justifyContent: "space-between", paddingRight: "8px" }}
      />

      {renderTextField(
        "sensitive_topics",
        "Sensitive topics list",
        "1. E.g. Queries around students dropping out\n2. E.g. Queries around how much scholarship one should expect",
        "control_definition",
        "List of topics that the bot should consider to be sensitive",
        8
      )}

      {renderTextField(
        "sensitive_topics",
        "Disclaimer Text",
        "E.g. The information provided on this platform is for general informational purposes only",
        "disclaimer",
        "The disclaimer to share alongside sensitive topic answers. (See a preview in the chatbot)",
        3
      )}

      {/* =================================================
      // NO-GO TOPICS SECTION
      ================================================= */}
      <PageSubheader>No-Go Topics</PageSubheader>
      <SectionTopBar
        style={{ justifyContent: "space-between", paddingRight: "8px" }}
      />

      {renderTextField(
        "no_go_topics",
        "No-Go topics list",
        "1. E.g. Comparison with competing Universities\n2. E.g. Questions unrelated to education",
        "control_definition",
        "List of topics that the bot should not answer",
        8
      )}

      {renderTextField(
        "no_go_topics",
        "Canned Response",
        "E.g. I do not have the answer to this question",
        "disclaimer",
        "The canned response for when users ask no-go topic questions. (See a preview in the chatbot)",
        3
      )}
    </>
  );
}
