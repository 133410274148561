import { createSlice } from "@reduxjs/toolkit";
import { TOKENS } from "../constants/Constants";

export const tokensSlice = createSlice({
  name: "tokens",
  initialState: {},
  reducers: {
    login: (state, action) => {
      localStorage.setItem(TOKENS, JSON.stringify(action.payload));
      state.value = action.payload;
    },
    logout: (state, action) => {
      state.value = {};
    },
  },
});

export const { login, logout } = tokensSlice.actions;
export default tokensSlice.reducer;
