/* eslint-disable indent */
import React, { useState } from "react";
import "./ChatPreview.css";
import {
  BotIcon,
  UserIcon,
  ChatSendIcon,
  DefaultChatIcon,
  AttachmentIcon,
} from "../utils/InsyncIconsUtil";

const ChatPreview = ({ formState }) => {
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [hoveredBubble, setHoveredBubble] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null);

  const toggleChatWindow = () => {
    setIsChatOpen(!isChatOpen);
  };

  const {
    brandLogo,
    headerTextFontSize,
    headerBackgroundGradientLeftColor,
    headerBackgroundGradientRightColor,
    headerTitleColor,
    headerTextFontWeight,
    chatHeaderTitle,
    headerNoticeText,
    headerBoxShadow,
    chatHeaderCrossIcon,
    chatBodyBackgroundColor,
    textMsgFontSize,
    chatSystemDisplayName,
    chatUserDisplayName,
    footerBgGradientLeftColor,
    footerBgGradientRightColor,
    inputFieldGhostText,
    chatInputBorderColor,
    userSendIconCircleFillColor,
    userSendIconCircleBorderColor,
    userSendIconArrowColor,
    footerNoticeText,
    showPoweredbyFooter,
    userTextMsgColor,
    userTextMsgBgColor,
    systemTextMsgColor,
    systemTextMsgBgColor,
    resizeFeatureEnabled,
    resizeWindowHeight,
    resizeWindowWidth,
    buttonBackgroundColor,
    buttonTextColor,
    buttonHoverBackgroundColor,
    buttonHoverTextColor,
    quickReplyBubbleRadius,
    quickReplyBubbleTextColor,
    quickReplyBubbleBorderColor,
    quickReplyBubbleHoverBgColor,
    quickReplyBubblesAlignment,
    quickReplyBubbleFontSize,
    chatButtonIcon,
  } = formState;

  // Styles for user and system messages
  const userMessageStyle = {
    color: userTextMsgColor,
    backgroundColor: userTextMsgBgColor,
  };

  const systemMessageStyle = {
    color: systemTextMsgColor,
    backgroundColor: systemTextMsgBgColor,
  };

  const buttonStyle = {
    backgroundColor: buttonBackgroundColor || "#e0e7ef",
    color: buttonTextColor || "#000",
  };

  const getBubbleStyle = (isHovered) => ({
    backgroundColor: isHovered
      ? quickReplyBubbleHoverBgColor || "#d3d9df"
      : buttonBackgroundColor || "#f0f0f0",
    color: quickReplyBubbleTextColor || "#000",
    borderColor: quickReplyBubbleBorderColor || "#ccc",
    fontSize: `${quickReplyBubbleFontSize || 13}px`,
    borderRadius: `${quickReplyBubbleRadius || 5}px`,
  });

  const getButtonStyle = (isHovered) => ({
    backgroundColor: isHovered
      ? buttonHoverBackgroundColor || "#d3d9df"
      : buttonBackgroundColor || "#e0e7ef",
    color: isHovered
      ? buttonHoverTextColor || "#fff"
      : buttonTextColor || "#000",
  });

  // Styles for resize feature
  const resizeStyles = resizeFeatureEnabled
    ? {
        ...(resizeWindowWidth !== undefined &&
          resizeWindowWidth !== null && {
            width: `${resizeWindowWidth}px`,
          }),
        ...(resizeWindowHeight !== undefined &&
          resizeWindowHeight !== null && {
            height: `${resizeWindowHeight}px`,
          }),
      }
    : {};

  return (
    <div className="chat-widget">
      {/* Chat Window */}
      {isChatOpen && (
        <div
          className="chat-preview"
          style={{
            backgroundColor: chatBodyBackgroundColor,
            ...resizeStyles,
          }}
        >
          <div
            className="chat-preview-header"
            style={{
              color: headerTitleColor,
              fontWeight: headerTextFontWeight,
              background: `linear-gradient(to right, ${headerBackgroundGradientLeftColor}, ${headerBackgroundGradientRightColor})`,
              boxShadow: headerBoxShadow,
              fontSize: `${headerTextFontSize}px`,
            }}
          >
            {brandLogo && (
              <img src={brandLogo} alt="logo" className="brand-logo" />
            )}
            <div
              className="chat-preview-header-title"
              style={{ fontSize: `${headerTextFontSize}px` }}
            >
              {chatHeaderTitle}
            </div>
            <div
              className="chat-preview-header-minimize"
              onClick={toggleChatWindow}
            >
              {chatHeaderCrossIcon === "minimizeIcon" ? "-" : "×"}
            </div>
          </div>
          {headerNoticeText && (
            <div className="chat-preview-header-notice">{headerNoticeText}</div>
          )}
          <div
            className="chat-preview-body"
            style={{ fontSize: textMsgFontSize }}
          >
            <div className="chat-preview-message chatbot">
              <div className="chat-preview-message-name">
                {chatSystemDisplayName}
              </div>
              <div className="chat-preview-message-content-container">
                <div className="chat-preview-message-icon chatbot">
                  <BotIcon />
                </div>
                <div
                  className="chat-preview-message-content"
                  style={systemMessageStyle}
                >
                  Greetings! I'm your virtual assistant, and I'm here to help.
                  Click an option below or type your question.
                </div>
              </div>
              <div className="chat-preview-buttons">
                {["Sample Button1", "Sample Button2", "Sample Button3"].map(
                  (buttonText, index) => (
                    <button
                      key={`button-${index}`}
                      style={getButtonStyle(hoveredButton === index)}
                      className="chat-preview-button"
                      onMouseEnter={() => setHoveredButton(index)}
                      onMouseLeave={() => setHoveredButton(null)}
                    >
                      {buttonText}
                    </button>
                  )
                )}
              </div>
            </div>
            <div className="chat-preview-message user">
              <div className="chat-preview-message-name user">
                {chatUserDisplayName}
              </div>
              <div className="chat-preview-message-content-container user">
                <div
                  className="chat-preview-message-content"
                  style={userMessageStyle}
                >
                  Choose the User text color
                </div>
                <div className="chat-preview-message-icon user">
                  <UserIcon />
                </div>
              </div>
            </div>
          </div>

          <div
            className="chat-preview-bubbles"
            style={{ justifyContent: quickReplyBubblesAlignment }}
          >
            {["Bubble1", "Bubble2", "Bubble3"].map((bubbleText, index) => (
              <div
                key={`bubble-${index}`}
                style={getBubbleStyle(hoveredBubble === index)}
                className="chat-preview-bubble"
                onMouseEnter={() => setHoveredBubble(index)}
                onMouseLeave={() => setHoveredBubble(null)}
              >
                {bubbleText}
              </div>
            ))}
          </div>

          <div
            className="chat-preview-footer"
            style={{
              background: `linear-gradient(to right, ${footerBgGradientLeftColor}, ${footerBgGradientRightColor})`,
            }}
          >
            <div className="chat-preview-footer-attachment">
              <AttachmentIcon />
            </div>
            <div
              className="chat-preview-footer-input"
              style={{ borderColor: chatInputBorderColor }}
            >
              {inputFieldGhostText}
            </div>
            <div className="chat-preview-footer-send">
              <ChatSendIcon
                userSendIconCircleFillColor={userSendIconCircleFillColor}
                userSendIconCircleBorderColor={userSendIconCircleBorderColor}
                userSendIconArrowColor={userSendIconArrowColor}
              />
            </div>
          </div>
          {(footerNoticeText || showPoweredbyFooter) && (
            <div className="poweredby-footer">
              <span>{footerNoticeText || "Powered by insync"}</span>
            </div>
          )}
        </div>
      )}

      {/* Chat Button */}
      <div className="chat-button" onClick={toggleChatWindow}>
        {/* If a custom chat button icon is provided, use it; otherwise, use a default icon */}
        {chatButtonIcon ? (
          <img src={chatButtonIcon} alt="Chat Icon" />
        ) : (
          <DefaultChatIcon />
        )}
      </div>
    </div>
  );
};

export default ChatPreview;
