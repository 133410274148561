import React, { useId } from "react";
import ContentLoader from "react-content-loader";

/**
 * Text Fields Loading placeholder component that simulates the loading state of the page.
 *
 * @param {number} numSections - The number of sections to render.
 * @returns {JSX.Element} The loading placeholder component.
 */
const TextFieldsLoadingPlaceholder = ({ numSections }) => {
  const baseId = useId();

  const sections = Array.from({ length: numSections }).map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={`${baseId}-${index}`}>
      {/* Subheader for section */}
      <rect
        x="20"
        y={140 + index * 300}
        rx="4"
        ry="4"
        width="200"
        height="24"
      />

      {/* First text field in section */}
      <rect
        x="20"
        y={180 + index * 300}
        rx="4"
        ry="4"
        width="calc(100% - 40px)"
        height="100"
      />

      {/* Second text field in section */}
      <rect
        x="20"
        y={300 + index * 300}
        rx="4"
        ry="4"
        width="calc(100% - 40px)"
        height="50"
      />
    </React.Fragment>
  ));

  return (
    <ContentLoader
      speed={2}
      width="50%"
      height="100vh"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {/* Page Header */}
      <rect x="20" y="20" rx="4" ry="4" width="120" height="30" />

      {sections}
    </ContentLoader>
  );
};

export default TextFieldsLoadingPlaceholder;
