import { useState, useEffect, useCallback } from "react";
import { apiCall } from "../api";

/**
 * Custom hook to fetch data from an API endpoint.
 *
 * @param {string} endpoint - The API endpoint to fetch data from.
 * @param {object} tokens - The authentication tokens for accessing the API.
 * @param {any} initialState - The initial state for the data.
 * @param {Array} dependencies - An array of triggers that cause data to be re-fetched when changed.
 * @returns {object} - An object containing the fetched data, loading state, and error state.
 * @property {any} data - The fetched data.
 * @property {boolean} loading - Whether the data is currently being fetched.
 * @property {Error|null} error - Any error that occurred during the fetch.
 */
const useFetch = (
  endpoint,
  tokens,
  dependencies = [],
  initialState = {},
  autoFetch = true
) => {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (url) => {
      setLoading(true);
      try {
        const response = await apiCall(url, {
          method: "GET",
          authTokens: tokens,
        });
        setData(response);
        setError(null); // Clear error on success
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [endpoint, tokens]
  );

  useEffect(() => {
    if (autoFetch) {
      fetchData(endpoint);
    }
  }, [fetchData, ...dependencies]);

  return { data, loading, error, fetchData };
};

export default useFetch;
