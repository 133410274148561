import { createSlice } from "@reduxjs/toolkit";
import { USER } from "../constants/Constants";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    saveUser: (state, action) => {
      localStorage.setItem(USER, JSON.stringify(action.payload));
      state.value = action.payload;
    },
    deleteUser: (state, action) => {
      state.value = {};
    },
  },
});

export const { saveUser, deleteUser } = userSlice.actions;
export default userSlice.reducer;
