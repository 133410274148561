import { isEmpty } from "lodash";
import { useState } from "react";

const useValidation = () => {
  const [errors, setErrors] = useState({});

  const validate = (data) => {
    let newErrors = {};
    let hasError = false;

    if (typeof data === "object" && !Array.isArray(data)) {
      // Validate object: check each key
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value !== "number" && isEmpty(value)) {
          newErrors[key] = "This field is required";
          hasError = true;
        }
      });
    } else if (Array.isArray(data)) {
      // Validate array: check each element
      if (isEmpty(data)) {
        newErrors.array = "This array is required";
        hasError = true;
      } else {
        data.forEach((item, index) => {
          if (isEmpty(item)) {
            newErrors[`item_${index}`] = "This array item is required";
            hasError = true;
          }
        });
      }
    } else if (typeof data === "string") {
      // Validate string
      if (isEmpty(data)) {
        newErrors.string = "This string is required";
        hasError = true;
      }
    } else if (typeof data === "number") {
      // Validate number: ensure it's not null
      if (data === null || data === undefined || isNaN(data)) {
        newErrors.number = "This number is required";
        hasError = true;
      }
    } else {
      // Default case for unsupported types
      newErrors.unKnown = "Unsupported data type";
      hasError = true;
    }

    setErrors(newErrors);

    return !hasError;
  };

  const clearErrors = (data) => {
    setErrors({}); // Clear errors in state

    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        if (item && typeof item === "object") {
          delete item.error; // Remove error property from objects
        }
      });
    } else if (typeof data === "object" && data !== null) {
      delete data.error; // Remove error property if it exists
    }
  };
  return {
    errors,
    validate,
    clearErrors,
  };
};

export default useValidation;
