// This is required to dynamically render the fields based on the type for Appearance Page

import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from "@mui/material";
import CustomTextField from "../CustomTextField";
import ColorPicker from "../ColorPicker";
import { LabelM } from "../Typography";
import FileUpload from "../FileUpload/FileUpload";
import { APPEARANCE_IMAGE_UPLOAD_ENDPOINT } from "../../constants/Constants";

const DynamicFormField = ({
  field,
  value,
  onChange,
  onImmediateChange,
  onBlur,
}) => {
  const effectiveValue = value !== undefined ? value : field.defaultValue;

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    onChange({
      target: {
        name,
        value:
          type === "number" ? (value === "" ? "" : parseFloat(value)) : value,
      },
    });
  };

  switch (field.type) {
    case "text":
    case "number":
      return (
        <CustomTextField
          className="form-field-column"
          label={field.label}
          placeholder={field.placeholder || field.label}
          value={effectiveValue}
          name={field.name}
          type={field.type}
          onChange={handleInputChange}
          onBlur={onBlur}
          fullBorder="true"
        />
      );
    case "color":
      return (
        <ColorPicker
          label={field.label}
          value={effectiveValue}
          name={field.name}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    case "image":
      return (
        <FileUpload
          field={field}
          onChange={onImmediateChange}
          endpoint={APPEARANCE_IMAGE_UPLOAD_ENDPOINT}
        />
      );
    case "select":
      return (
        <div className="form-field">
          <LabelM
            style={{ fontSize: "1rem", textAlign: "left", maxWidth: "40%" }}
          >
            {field.label}
          </LabelM>
          <FormControl className="select-field">
            <Select
              // default to first option if defaultValue and value are both undefined
              value={effectiveValue || field.options[0]}
              onChange={onChange}
              onBlur={onBlur}
              name={field.name}
              className="select-field"
            >
              {field.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    case "radio":
      return (
        <div className="form-field">
          <LabelM
            style={{ fontSize: "1rem", textAlign: "left", maxWidth: "40%" }}
          >
            {field.label}
          </LabelM>
          <RadioGroup
            value={effectiveValue || ""}
            name={field.name}
            onChange={onChange}
            onBlur={onBlur}
            row
          >
            {field.options.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </div>
      );
    case "boolean":
      return (
        <div className="form-field">
          <LabelM
            style={{ fontSize: "1rem", textAlign: "left", maxWidth: "40%" }}
          >
            {field.label}
          </LabelM>
          <Switch
            checked={Boolean(effectiveValue)}
            onChange={onChange}
            name={field.name}
            onBlur={onBlur}
          />
        </div>
      );
    default:
      return null;
  }
};

export default DynamicFormField;
