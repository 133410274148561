import { configureStore } from "@reduxjs/toolkit";
import tokensSlice from "../slices/TokensSlice";
import userSlice from "../slices/UserSlice";
import appsSlice, { initializeApp } from "../slices/AppsSlice";

export const store = configureStore({
  reducer: {
    tokens: tokensSlice,
    user: userSlice,
    apps: appsSlice,
  },
});

// Load initial state from localStorage and dispatch to store
const selectedApp = localStorage.getItem("selectedApp");
if (selectedApp) {
  store.dispatch(initializeApp(JSON.parse(selectedApp)));
}

export default store;
