/* eslint-disable max-len */
/* eslint-disable no-undef */
import axios from "axios";

import {
  getAccessToken,
  //   setAccessToken,
  deleteAccessToken,
  fetchUserToken,
} from "./AuthService";

const api = axios.create({
  baseURL: process.env.REACT_APP_CONFIGURATOR_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
  refreshSubscribers.map((cb) => cb(token));
};

const resetRefreshLogic = () => {
  isRefreshing = false;
  refreshSubscribers = [];
};

const refreshTokenHandler = async () => {
  isRefreshing = true;
  try {
    const newToken = await fetchUserToken();
    if (newToken) {
      onRefreshed(newToken);
    } else {
      throw new Error("Token Not Refreshed");
    }
  } catch (error) {
    deleteAccessToken();
    window.location.href = process.env.REACT_APP_DEV_BACKOFFICE_URL;
  } finally {
    resetRefreshLogic();
  }
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401 && !originalRequest._retry && !isRefreshing) {
      originalRequest._retry = true;
      await refreshTokenHandler();
      return api(originalRequest);
    }
    if (status === 401 && isRefreshing) {
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = "Bearer " + token;
          resolve(api(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

/**
 * Makes an API call to the specified URL with the given options.
 *
 * @param {string} urlPart - The URL part (endpoint) to call.
 * @param {Object} [apiOptions={}] - The options for the API call.
 * @param {('get'|'post'|'patch'|'delete')} apiOptions.method - The HTTP method to use (e.g., 'GET', 'POST').
 * @param {Object} [apiOptions.body={}] - The request body to send (for methods like POST).
 * @param {Object} [apiOptions.headers={}] - Additional headers to send with the request.
 * @param {Object} [apiOptions.authTokens] - Authentication tokens.
 * @param {string} apiOptions.authTokens.user_token - The user token for authorization.
 * @param {string} apiOptions.authTokens.refresh_token - The refresh token to generate new tokens.
 * @param {Function} [callback=null] - The callback function to call with new tokens after a token refresh.
 * @returns {Promise<Object>} - Resolves with the response data or rejects with an error.
 * @throws {Error} If the API call fails.
 */

export const apiCall = async (urlPart, apiOptions = {}, callback = null) => {
  const headers = apiOptions.headers ? { ...apiOptions.headers } : {};

  if (apiOptions.authTokens) {
    headers.Authorization = `Bearer ${apiOptions.authTokens?.user_token}`;
  }

  // Only set 'Content-Type' to 'application/json' if body is not FormData
  if (!apiOptions.body || !(apiOptions.body instanceof FormData)) {
    if (!headers["Content-Type"]) {
      headers["Content-Type"] = "application/json";
    }
  }

  const options = {
    method: apiOptions.method,
    headers,
  };

  if (apiOptions.body) {
    if (apiOptions.body instanceof FormData) {
      options.body = apiOptions.body;
    } else {
      options.body = JSON.stringify(apiOptions.body);
    }
  }

  const url = `${process.env.REACT_APP_CONFIGURATOR_API_URL}${urlPart}`;

  try {
    const response = await fetch(url, options);
    // console.log("apiOptions", apiOptions.authTokens);
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    if (response.status === 401 && apiOptions.authTokens) {
      // console.log("first");
      const tokens = await apiCall("/v1/users/generate_access_token", {
        method: "POST",
        body: { refresh_token: apiOptions.authTokens.refresh_token },
      });

      const res = await apiCall(urlPart, {
        ...apiOptions,
        authTokens: tokens,
      });

      if (callback) {
        callback(tokens);
      }

      return res;
    }
    throw new Error("API call failed with status " + response.status);
  } catch (error) {
    console.error("Error during API call:", error);
    // throw error;
  }
};

export default api;
