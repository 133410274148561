import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Collapse, Alert } from "@mui/material";
import DynamicFormField from "../../components/Appearance/DynamicFormField";
import PushToProduction from "../../components/PushToProduction";
import useAutoSave from "../../hooks/useAutoSave";
import useFetch from "../../hooks/useFetch";
import usePutRequest from "../../hooks/usePutRequest";
import {
  BASIC_SETTINGS_FIELDS,
  ADVANCED_SETTINGS_FIELDS,
} from "../../configs/Appearance/BotAppearanceConfig";
import { LabelM, PageHeader, PageSubheader } from "../../components/Typography";
import "./Appearance.css";
import {
  ExpandLessIcon,
  ExpandMoreIcon,
} from "../../components/utils/InsyncIconsUtil";
import AppearanceLoadingPlaceholder from "../../components/LoadingPlaceholders/AppearanceLoadingPlaceholder";
import ChatPreview from "../../components/ChatPreview/ChatPreview";
import {
  APPEARANCE_GET_ENDPOINT,
  APPEARANCE_UPDATE_ENDPOINT,
} from "../../constants/Constants";

// Utility function to map internal names to form state names
const mapApiToFormState = (apiData, fieldsConfig) => {
  const formState = {};

  Object.keys(fieldsConfig).forEach((section) => {
    fieldsConfig[section].forEach((field) => {
      if (field.internal_name) {
        formState[field.name] =
          apiData[field.internal_name] ?? field.defaultValue;
      }
    });
  });

  return formState;
};

// Utility function to map form state fields to API payload fields
const mapFormStateToApi = (formState, fieldsConfig) => {
  const apiPayload = {};

  Object.keys(fieldsConfig).forEach((section) => {
    fieldsConfig[section].forEach((field) => {
      if (field.internal_name) {
        apiPayload[field.internal_name] = formState[field.name];
      }
    });
  });

  return apiPayload;
};

const mergeFieldsConfig = (basicFields, advancedFields) => {
  const mergedConfig = { ...basicFields };

  Object.keys(advancedFields).forEach((key) => {
    if (mergedConfig[key]) {
      mergedConfig[key] = [...mergedConfig[key], ...advancedFields[key]];
    } else {
      mergedConfig[key] = [...advancedFields[key]];
    }
  });

  return mergedConfig;
};

const Appearance = () => {
  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const [pendingSave, setPendingSave] = useState(false);

  const [formState, setFormState] = useState({});
  const [basicOpen, setBasicOpen] = useState(true);
  const [advancedOpen, setAdvancedOpen] = useState(false);

  const mergedFieldsConfig = mergeFieldsConfig(
    BASIC_SETTINGS_FIELDS,
    ADVANCED_SETTINGS_FIELDS
  );

  // Fetch initial data
  const { data, loading, error } = useFetch(
    `${APPEARANCE_GET_ENDPOINT}?appId=${currentApp?.id}`,
    tokens,
    []
  );

  const { sendPutRequest } = usePutRequest(
    `${APPEARANCE_UPDATE_ENDPOINT}?appId=${currentApp?.id}`,
    tokens
  );

  // Map fetched data to formState
  useEffect(() => {
    if (data) {
      const basicFormState = mapApiToFormState(data, mergedFieldsConfig);
      const advancedFormState = mapApiToFormState(
        data,
        ADVANCED_SETTINGS_FIELDS
      );
      setFormState({ ...basicFormState, ...advancedFormState });
    } else {
      // If no initial data, apply default values
      const basicDefaultState = mapApiToFormState({}, mergedFieldsConfig);
      const advancedDefaultState = mapApiToFormState(
        {},
        ADVANCED_SETTINGS_FIELDS
      );
      setFormState({ ...basicDefaultState, ...advancedDefaultState });
    }
  }, [data]);

  // For saving configurations
  useEffect(() => {
    if (pendingSave) {
      saveConfigurations();
      setPendingSave(false);
    }
  }, [formState, pendingSave]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Allow setting field to empty string.
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleImmediateChange = (e) => {
    handleInputChange(e);
    setPendingSave(true);
  };

  const handleFieldBlur = () => {
    setPendingSave(true);
  };

  // Define saveConfigurations function
  const saveConfigurations = useCallback(() => {
    const payload = mapFormStateToApi(formState, mergedFieldsConfig);

    sendPutRequest({ appearance: { value: payload } })
      .then(() => console.log("Successfully updated form state"))
      .catch((error) => console.error("Failed to update form state", error));
  }, [formState, mergedFieldsConfig, sendPutRequest]);

  // Use the handleBlur hook
  const { handleBlur } = useAutoSave(saveConfigurations, [formState]);

  if (loading) {
    return <AppearanceLoadingPlaceholder sections={2} />;
  }

  if (error) {
    return <Alert severity="error">Failed to load appearance settings.</Alert>;
  }

  return (
    <div className="appearance-container">
      {/* Settings Section */}
      <div className="settings-section">
        <PageHeader>Appearance</PageHeader>

        <div className="section-title-container">
          <PageSubheader>Basic Settings</PageSubheader>
          <PageSubheader
            style={{ cursor: "pointer" }}
            onClick={() => setBasicOpen(!basicOpen)}
          >
            {basicOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </PageSubheader>
        </div>
        <Collapse in={basicOpen} timeout="auto" unmountOnExit>
          {Object.keys(BASIC_SETTINGS_FIELDS).map((section) => (
            // eslint-disable-next-line react/jsx-key
            <div>
              <div key={section} className="subsection-container">
                <LabelM
                  style={{
                    textAlign: "left",
                    paddingBottom: "0.5rem",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {section}
                </LabelM>
              </div>
              <div>
                {BASIC_SETTINGS_FIELDS[section].map((field, index) => (
                  <div key={index} className="subfield-container">
                    <DynamicFormField
                      field={field}
                      value={formState[field.name]}
                      onChange={handleInputChange}
                      onImmediateChange={handleImmediateChange}
                      onBlur={handleFieldBlur}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Collapse>

        <div className="section-title-container">
          <PageSubheader>Advanced Settings</PageSubheader>
          <PageSubheader
            style={{ cursor: "pointer" }}
            onClick={() => setAdvancedOpen(!advancedOpen)}
          >
            {advancedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </PageSubheader>
        </div>
        <Collapse in={advancedOpen} timeout="auto" unmountOnExit>
          {Object.keys(ADVANCED_SETTINGS_FIELDS).map((section) => (
            // eslint-disable-next-line react/jsx-key
            <div>
              <div key={section} className="subsection-container">
                <LabelM
                  style={{
                    textAlign: "left",
                    paddingBottom: "0.5rem",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {section}
                </LabelM>
              </div>
              <div>
                {ADVANCED_SETTINGS_FIELDS[section].map((field, index) => (
                  <div key={index} className="subfield-container">
                    <DynamicFormField
                      field={field}
                      value={formState[field.name]}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Collapse>

        <PushToProduction />
      </div>

      {/* Preview Section */}
      <div className="preview-section">
        <ChatPreview formState={formState} />
      </div>
    </div>
  );
};

export default Appearance;
