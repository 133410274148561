import React from "react";

/**
 *
 * @param {React.JSX.Element} children
 * @returns React.JSX.Element
 */

export default function PageWindow({ children }) {
  return <div className="page-window">{children}</div>;
}
