// usePatchRequest.js
import { useState } from "react";
import { apiCall } from "../api";

/**
 * Custom hook to handle PATCH requests.
 *
 * @param {object} tokens - The authentication tokens for accessing the API.
 * @returns {object} - An object containing the response data, loading state, error state,
 * and a function to send the PATCH request.
 * @property {any} data - The response data from the API.
 * @property {boolean} loading - Whether the PATCH request is currently in progress.
 * @property {Error|null} error - Any error that occurred during the PATCH request.
 * @property {function} sendPatchRequest - Function to send the PATCH request.
 */
const usePatchRequest = (tokens) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  /**
   * Function to send a PATCH request to the given URL.
   *
   * @param {string} url - The API endpoint URL where the PATCH request will be sent.
   * @param {object} body - The request body to be sent with the PATCH request.
   * @returns {Promise<any>} - The response data from the API.
   * @throws {Error} - Throws an error if the PATCH request fails.
   */
  const sendPatchRequest = async (url, body) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiCall(url, {
        method: "PATCH",
        authTokens: tokens,
        body,
      });

      setData(response || []);
      setLoading(false);
      return response;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw err;
    }
  };

  return { data, loading, error, sendPatchRequest };
};

export default usePatchRequest;
