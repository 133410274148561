import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveApps, selectApp } from "./slices/AppsSlice";
import { apiCall } from "./api";
import { COLORS } from "./constants/Colors";
import {
  MenuExpand,
  MenuShrink,
  NavigationIcons,
} from "./components/utils/InsyncIconsUtil";

const MENU_ITEMS_APP = [
  {
    title: "Home",
    path: "/",
    icon: "home",
  },
  {
    title: "Knowledge",
    children: [
      { title: " Websites", path: "/knowledge/websites" },
      { title: " Files", path: "/knowledge/files" },
    ],
    icon: "knowledge",
  },
  {
    title: "Controls",
    path: "/controls",
    icon: "controls",
  },
  {
    title: "Integrations",
    path: "/integrations",
    icon: "integrations",
  },
  {
    title: "Appearance",
    path: "/appearance",
    icon: "appearance",
  },
  {
    title: "AI Personality",
    path: "/ai-personality",
    icon: "ai-personality",
  },
  {
    title: "Smart Probe",
    path: "/smart-probe",
    icon: "smart-probe",
  },
  {
    title: "Bot Testing",
    path: "/bot-testing",
    icon: "bot-testing",
  },
  {
    title: "Access Control",
    path: "/access-control",
    icon: "access-control",
  },
];

const MENU_ITEMS_ADMIN = [
  {
    title: "Admin Home",
    path: "/admin",
    alternatePath: "/",
    icon: "home",
  },
  {
    title: "Clients",
    path: "/clients",
    icon: "clients",
  },
  {
    title: "Users",
    path: "/users",
    icon: "users",
  },
];

export default function Navbar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const tokens = useSelector((state) => state?.tokens.value);
  const [open, setOpen] = useState({});
  const apps = useSelector((state) => state?.apps.list || []);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const [isApp, setIsApp] = useState(false);

  useEffect(() => {
    if (apps.length === 0) {
      const fetchApps = async () => {
        let appsList = await apiCall("/v1/apps?status=active", {
          method: "get",
          authTokens: tokens,
        });
        if (appsList) {
          dispatch(saveApps(appsList));
        }
      };
      fetchApps();
    }
  }, [apps.length, dispatch]);

  const currentPathSegments = location.pathname.split("/");
  const currentAppNameFromUrl = currentPathSegments[1];

  useEffect(() => {
    let selectedApp = apps.find(
      (app) => app.url_code === currentAppNameFromUrl
    );
    if (currentAppNameFromUrl && selectedApp) {
      if (!currentApp) {
        dispatch(selectApp(selectedApp));
      }
      setIsApp(true);
    } else {
      setIsApp(false);
    }
  }, [apps, currentAppNameFromUrl]);

  const menuItems = isApp ? MENU_ITEMS_APP : MENU_ITEMS_ADMIN;

  useEffect(() => {
    menuItems.forEach((item) => {
      if (item.children) {
        item.children.forEach((subItem) => {
          const resolvedPath = isApp
            ? `/${currentAppNameFromUrl}${subItem.path}`
            : `${subItem.path}`;
          if (location.pathname === resolvedPath) {
            setOpen((prevOpen) => ({
              ...prevOpen,
              [item.title]: true,
            }));
          }
        });
      }
    });
  }, [location.pathname, menuItems, currentAppNameFromUrl, isApp]);

  const handleClick = (title) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [title]: !prevOpen[title],
    }));
  };

  const isSelected = (path) => {
    const resolvedPath = isApp ? `/${currentAppNameFromUrl}${path}` : path;
    return (
      location.pathname === resolvedPath ||
      location.pathname.startsWith(resolvedPath + "/")
    );
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
      <List component="nav">
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.children ? (
              <>
                <ListItemButton
                  onClick={() => handleClick(item.title)}
                  selected={item.children?.some((subItem) =>
                    isSelected(subItem.path)
                  )}
                >
                  <NavigationIcons icon={item.icon} />
                  <ListItemText
                    sx={{ paddingLeft: "12px" }}
                    primary={item.title}
                  />
                  {open[item.title] ? <MenuExpand /> : <MenuShrink />}
                </ListItemButton>
                <Collapse in={open[item.title]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((subItem, subIndex) => (
                      <ListItemButton
                        disableRipple
                        key={subIndex}
                        sx={{
                          pl: 4,
                          pb: 0,
                          pt: 0,
                        }}
                        selected={isSelected(subItem.path)}
                        component={Link}
                        to={
                          isApp
                            ? `/${currentAppNameFromUrl}${subItem.path}`
                            : subItem.path
                        }
                      >
                        <NavigationIcons icon={subItem.icon} />
                        <ListItemText
                          sx={{ paddingLeft: "12px" }}
                          primary={subItem.title}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItemButton
                component={Link}
                to={isApp ? `/${currentAppNameFromUrl}${item.path}` : item.path}
                key={index}
                selected={
                  isSelected(item.alternatePath) || isSelected(item.path)
                }
              >
                <NavigationIcons icon={item.icon} />
                <ListItemText
                  sx={{ paddingLeft: "12px" }}
                  primary={item.title}
                />
              </ListItemButton>
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <Drawer
        ModalProps={{
          keepMounted: false,
        }}
        variant="permanent"
        PaperProps={{
          sx: {
            bgcolor: COLORS.navBg,
            marginTop: "67px",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
