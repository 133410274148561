import React, { useEffect, useCallback } from "react";
import { InputAdornment, TextField } from "@mui/material";
import useSearch from "../hooks/useSearch";
import { SearchIcon } from "./utils/InsyncIconsUtil";

/**
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.data - The data array used for local filtering.
 * @param {string} [props.url] - The API endpoint used for remote search.
 * @param {Array<string>} [props.searchKeys] - The keys to search within in the data objects.
 * @param {function} [props.onSearchResults] - Callback function to handle filtered results.
 * @param {"onChange"|"onEnter"} [props.triggerOn="onChange"] - Determines if the search should
 * trigger on every input change or when Enter is pressed.
 * @returns {JSX.Element} A `TextField` component with search functionality.
 */

const SearchBar = ({
  data,
  url,
  searchKeys,
  queryParam,
  onChange = () => {},
  onSearchResults = () => {},
  triggerOn = "onChange",
}) => {
  const { query, setQuery, filteredData, fetchSearchedResults } = useSearch({
    data,
    url,
    searchKeys,
    queryParam,
    triggerOn,
  });

  const handleInputChange = (e) => {
    onChange(e.target.value);
    setQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Trigger search on Enter
      fetchSearchedResults();
    }
  };

  // Use useCallback to memoize the onSearchResults function
  const handleSearchResults = useCallback(() => {
    onSearchResults(filteredData);
  }, [filteredData]);

  useEffect(() => {
    handleSearchResults();
  }, [handleSearchResults]);

  return (
    <TextField
      placeholder="Search"
      margin="dense"
      sx={{
        width: "300px",
        maxHeight: "46px",
        margin: "0px",
        "& input": {
          padding: "10px 20px 10px 0px !important",
          border: "none",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px 10px 10px 10px",
          border: "1px solid #9DA6B6",
          paddingLeft: "20px",
        },
      }}
      value={query}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={handleInputChange}
      onKeyDown={triggerOn === "onEnter" ? handleKeyPress : undefined}
    />
  );
};

export default SearchBar;
