import { createTheme } from "@mui/material";
import { COLORS } from "./constants/Colors";
import {
  GENERIC_BUTTON_FONT_SIZE,
  GENERIC_LABEL_FONT_SIZE,
} from "./constants/Constants";

const theme = createTheme({
  typography: {
    fontFamily: "THICCCBOI",
  },

  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "PrimaryButton" },
          style: {
            height: "46px",
            padding: "23px 38px 23px 38px",
            gap: "6px",
            borderRadius: "12px 12px 12px 12px",
            opacity: "0px",
            backgroundColor: COLORS.PRIMARY_COLOR_2,
            color: "#FFF",
            fontSize: GENERIC_BUTTON_FONT_SIZE,
            fontWeight: 700,
            textTransform: "none",
            ":hover": {
              backgroundColor: "#E66A00",
            },
          },
        },
        {
          props: { variant: "PrimaryButtonBlue" },
          style: {
            height: "46px",
            padding: "23px 38px 23px 38px",
            gap: "6px",
            borderRadius: "12px 12px 12px 12px",
            opacity: "0px",
            backgroundColor: COLORS.PRIMARY_COLOR_3,
            color: "#FFF",
            fontSize: GENERIC_BUTTON_FONT_SIZE,
            fontWeight: 700,
            textTransform: "none",
            ":hover": {
              backgroundColor: "#2966C7",
            },
            ":disabled": {
              backgroundColor: "#327DF661",
              color: "#FFF",
              cursor: "none",
            },
          },
        },
        {
          props: { variant: "SecondaryButton" },
          style: {
            height: "46px",
            padding: "23px 38px 23px 38px",
            gap: "6px",
            borderRadius: "12px 12px 12px 12px",
            opacity: "0px",
            backgroundColor: "#FFF",
            border: "1px solid #DCDEE2",
            fontSize: GENERIC_BUTTON_FONT_SIZE,
            fontWeight: 700,
            textTransform: "none",
          },
        },
        {
          props: { variant: "LightBlueBGButton" },
          style: {
            height: "46px",
            padding: "23px 38px 23px 38px",
            gap: "6px",
            borderRadius: "12px 12px 12px 12px",
            opacity: "0px",
            background: "#F3F7FF",
            color: "#327DF6",
            border: "1px solid #327DF6",
            fontSize: GENERIC_BUTTON_FONT_SIZE,
            fontWeight: 400,
            textTransform: "none",
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginRight: "8px",
          fontSize: "13px",
          lineHeight: "18.2px",
          width: "674px",
          gap: "10px",
          opacity: "0px",
          "&::placeholder": {
            color: "#9DA6B6",
          },
          "&.Mui-focusVisible": {
            background: "transparent",
          },
          "& input": {
            // height: "20px !important",
            padding: "14px 20px",
            borderRadius: "10px 10px 10px 10px",
            border: "1px solid #9DA6B6",
          },
          "& textarea": {
            // height: "20px !important",
            padding: "14px 20px",
            borderRadius: "10px 10px 10px 10px",
            border: "1px solid #9DA6B6",
          },
          "& fieldset": {
            display: "none",
          },
          "& .MuiOutlinedInput-root": {
            padding: "0px",
            minHeight: "50px",
          },
          "& .Mui-error": {
            color: "#DC2B2B !important",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          marginRight: "8px",
          fontSize: "13px",
          lineHeight: "18.2px",
          width: "674px",
          gap: "10px",
          opacity: "0px",
          "& .Mui-error": {
            color: "#DC2B2B !important",
          },
          "&.Mui-focusVisible": {
            background: "transparent",
          },
          "& input": {
            // height: "20px !important",
            padding: "14px 20px",
            borderRadius: "10px 10px 10px 10px",
            border: "1px solid #9DA6B6",
            "&::placeholder": {
              color: "#9DA6B6",
            },
          },
          "& fieldset": {
            display: "none",
          },
          "& .MuiOutlinedInput-root": {
            padding: "0px",
            height: "50px",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontSize: GENERIC_LABEL_FONT_SIZE,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          fontWeight: "400",
          lineHeight: "18px",
          textAlign: "left",
          color: "#4C5A71",
          "&:hover": {
            backgroundColor: "#F3F7FF !important",
            textDecoration: "underline",
          },
          "&.Mui-selected": {
            backgroundColor: "#F3F7FF",
            "& span": {
              fontWeight: "700 !important",
            },
            "& svg": {
              fontWeight: "700 !important",
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.PRIMARY_COLOR_1,
          "&  .MuiToolbar-root": {
            justifyContent: "space-between",
            // marginLeft: "50px",
            // marginRight: "50px",
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "TableAddTag" },
          style: {
            width: "109px",
            height: "26px",
            fontFamily: "THICCCBOI",
            fontsize: "14px",
            fontweight: "400",
            lineheight: "16.14px",
            textalign: "left",
            background: "transparent",
            borderRadius: "18px 18px 18px 18px",
            padding: "5px, 15px, 5px, 15px",
            border: "1px solid #9DA6B6",
            textTransform: "none",
            color: "#4C5A71",
          },
        },
      ],
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          fontSize: 13,
          "& .MuiTableCell-root": {
            borderBottom: "1px solid #D8DCE2",
            verticalAlign: "middle",
            maxWidth: "350px",
            paddingTop: "0px",
            paddingBottom: "0px",
          },
          "& .MuiTableCell-head": {
            backgroundColor: "#FFF3E4",
            fontFamily: "THICCCBOI",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "16.14px",
            textAlign: "left",
            height: "58px",
            textTransform: "uppercase",
          },
        },
      },
    },
  },
});

export default createTheme(theme);
