import { MenuItem, Select } from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";

/**
 * A dropdown selector component that allows the user to choose an option from a list.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.items - The list of items to display in the dropdown.
 * @param {string} props.defaultValue - The default value selected in the dropdown.
 * @param {Function} props.setValue - The function to set the selected value.
 * @param {Object} [props.customStyles] - Optional custom styles to apply to the component.
 * @param {Boolean} [props.error] - TO setl error to the component.
 * @returns {React.JSX.Element} The rendered JSX component.
 */

export default function Selector({
  items,
  defaultValue,
  setValue,
  customStyles,
  onBlur = () => {},
  error = false,
}) {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    const clickedValue = event.target.value;
    setSelectedValue(clickedValue);
    setValue(clickedValue);
  };

  if (items.length === 0) return <span />;

  return (
    <Select
      onBlur={onBlur}
      displayEmpty
      sx={{
        ...customStyles,
        color: selectedValue === "" ? "#9DA6B6" : "",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "18.45px",
        textAlign: "left",
      }}
      error={error}
      placeholder="Select"
      value={selectedValue}
      onChange={handleChange}
      renderValue={(val) => {
        if (isEmpty(val)) {
          return "Select";
        }
        return items.find((el) => el.value === val)?.title;
      }}
    >
      <MenuItem
        value=""
        disabled
        sx={{
          color: "#9DA6B6",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "18.45px",
          textAlign: "left",
        }}
      >
        Select
      </MenuItem>
      {items.map((item) => {
        return (
          <MenuItem key={`item-${item.value}}`} value={item.value}>
            {item.title}
          </MenuItem>
        );
      })}
    </Select>
  );
}
