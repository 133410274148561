import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Grid, IconButton } from "@mui/material";
import {
  PageHeader,
  PageSubheader,
  SubtitleM,
} from "../../components/Typography";
import useFetch from "../../hooks/useFetch";
import IntegrationSidebar from "./IntegrationSidebar";
import TextFieldsLoadingPlaceholder from "../../components/LoadingPlaceholders/TextFieldsLoadingPlaceholder";

export default function Integrations() {
  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);

  // Fetching files data from the API
  const {
    data: { configs, integrations },
    loading,
    error,
  } = useFetch(`/v1/apps/${currentApp.id}/integrations`, tokens, [], {});

  // Display loading state while fetching data
  if (loading) {
    return <TextFieldsLoadingPlaceholder numSections={2} />;
  }

  const renderCrmElements = (type) => {
    return (
      configs && (
        <Grid
          sx={{
            margin: "10px",
            marginTop: "25px",
            // width: "50%",
            rowGap: "26px",
            columnGap: "26px",
          }}
          container
          // columns={3}
          columnSpacing={1}
          // rowGap={1}
        >
          {configs[type]?.map((c) => {
            return (
              <Grid
                item
                key={c.name}
                lg={3}
                sx={{
                  border: "1px solid #D8DCE2",
                  borderRadius: "10px",
                  padding: "30px 20px",
                  height: "126px",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  minWidth: "364px !important",
                  maxWidth: "364px !important",
                  width: "364px",
                  // verticalAlign: "center",
                }}
                // onClick={() => handleAppClick(app)}
              >
                <div className="crm-image-container">
                  {<img className="crm-image" src={c.icon} />}
                </div>
                <div className="flex-column app-logo-title-container">
                  <SubtitleM
                    style={{
                      wordBreak: "break-word",
                      color: "#000000",
                      textAlign: "left",
                      maxWidth: "100%",
                      textTransform: "capitalize",
                    }}
                  >
                    {c.name}
                  </SubtitleM>
                </div>
                <IntegrationSidebar
                  key={c.name + "-crm-sidebar"}
                  system={c}
                  integrations={integrations || []}
                />
              </Grid>
            );
          })}
        </Grid>
      )
    );
  };

  return (
    <>
      <PageHeader>Integrations</PageHeader>

      <PageSubheader>Agent System Integration</PageSubheader>

      <SubtitleM>
        Integrate your systems for handoff to Agents through Live Chat / Email
        Cases
      </SubtitleM>

      {renderCrmElements("CRM")}

      <PageSubheader>Learning Management System</PageSubheader>
      <SubtitleM>
        Integrate your student LMS to provide assistance during their learning{" "}
      </SubtitleM>

      {renderCrmElements("LMS")}
    </>
  );
}
