/**
 * String Constants
 */
export const TOKENS = "tokens";
export const USER = "user";

/**
 * Labels
 */
export const GENERIC_LABEL_FONT_SIZE = "20px";
export const GENERIC_BUTTON_FONT_SIZE = "18px";

/**
 * CONFIGS
 */
export const USER_ROLES = [
  { title: "User", value: "user" },
  { title: "System Admin", value: "super_admin" },
];

export const APP_USER_ROLES = [
  { title: "Client", value: "client" },
  { title: "Client Admin", value: "client_admin" },
  //   { title: "Editor", value: "editor" },
  { title: "Admin", value: "admin" },
  { title: "Super Admin", value: "super_admin" },
];

export const APPEARANCE_IMAGE_UPLOAD_ENDPOINT = "/v1/appearances/upload_file";
export const APPEARANCE_GET_ENDPOINT = "/v1/appearances";
export const APPEARANCE_UPDATE_ENDPOINT = "/v1/appearances";

export const FILES_GET_ENDPOINT = "/v1/files";
export const FILES_PRESIGNED_URL_ENDPOINT = "/v1/files/presigned_urls";
export const FILES_COMPLETE_UPLOAD_ENDPOINT = "/v1/files/complete_upload";
export const FILES_CLOUDFRONT_BASE_URL =
  "https://d1lm68cljgdm5v.cloudfront.net";

export const PLACEHOLDER_LOGO_URL_S3 = "/image (2).png";

export const CLIENT_TYPES = [
  { title: "Higher Edu - Enroll", value: "us_eduenroll" },
  { title: "Higher Edu - Engage", value: "us_devry" },
  { title: "Ecommerce", value: "us_configdefault" },
];
