import React from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { apiCall } from "../../api";
import { login } from "../../slices/TokensSlice";
import { saveUser } from "../../slices/UserSlice";
import "./LoginPage.css"; // Import the CSS file

function LoginPage() {
  const dispatch = useDispatch();
  const refreshTokens = (tokens) => dispatch(login(tokens));

  const getUser = async (tokens) => {
    try {
      const user = await apiCall(
        "/v1/users/get_info",
        {
          method: "GET",
          authTokens: tokens,
        },
        refreshTokens
      );
      dispatch(saveUser(user));
    } catch (error) {
      console.error("Error while getting user info:", error);
    }
  };

  const handleSuccess = async (credentialResponse) => {
    const authorizationCode = credentialResponse.code;
    try {
      const tokens = await apiCall("/auth/google", {
        method: "POST",
        body: { code: authorizationCode },
      });
      dispatch(login(tokens));
      getUser(tokens);
    } catch (error) {
      console.error("Error exchanging authorization code:", error);
    }
  };

  const handleError = (errorResponse) => {
    console.error("Google login failed", errorResponse);
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleError,
    useOneTap: true,
    flow: "auth-code",
  });

  const logOut = () => {
    googleLogout();
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <h1>Login</h1>
        <p>Please sign in with your Google account to continue.</p>
        <div className="google-login-button">
          <button
            onClick={() => {
              loginWithGoogle();
            }}
          >
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
