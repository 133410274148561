/* eslint-disable react/no-array-index-key */
import React, { useId } from "react";
import ContentLoader from "react-content-loader";

/**
 * Loading placeholder for the Files component.
 *
 * @param {number} numRows - Number of rows to render.
 * @param {number} numCols - Number of columns to render.
 * @returns {JSX.Element} The loading placeholder component.
 */
const TabularLoadingPlaceholder = ({ numRows, numCols }) => {
  const baseId = useId();
  const rowHeadersY = 140;
  const rowHeight = 60;
  const colWidth = 150;
  const colSpacing = 20;
  const rowSpacing = 20;

  const rows = Array.from({ length: numRows }).map((_, rowIndex) => (
    <React.Fragment key={`${baseId}-row-${rowIndex}`}>
      {Array.from({ length: numCols }).map((_, colIndex) => (
        <rect
          key={`${baseId}-col-${colIndex}`}
          x={20 + colIndex * (colWidth + colSpacing)}
          y={rowHeadersY + rowIndex * (rowHeight + rowSpacing)}
          rx="4"
          ry="4"
          width={colWidth}
          height="40"
        />
      ))}
    </React.Fragment>
  ));

  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100vh"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {/* Page Header */}
      <rect x="20" y="20" rx="4" ry="4" width="300" height="30" />

      {/* SectionTopBar/Search */}
      <rect x="20" y="60" rx="4" ry="4" width="300" height="40" />
      <rect x="340" y="60" rx="4" ry="4" width="150" height="40" />

      {/* Row Headers */}
      {Array.from({ length: numCols }).map((_, colIndex) => (
        <rect
          key={`${baseId}-header-${colIndex}`}
          x={20 + colIndex * (colWidth + colSpacing)}
          y={rowHeadersY - rowSpacing}
          rx="4"
          ry="4"
          width={colWidth}
          height="20"
        />
      ))}

      {/* Rows */}
      {rows}
    </ContentLoader>
  );
};

export default TabularLoadingPlaceholder;
