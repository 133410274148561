import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, CircularProgress } from "@mui/material";
import usePostRequest from "../../hooks/usePostRequest";

const FileUpload = ({ field, onChange, accept = "", endpoint }) => {
  const tokens = useSelector((state) => state?.tokens.value);
  const fileInputRef = useRef();

  const { loading: uploading, sendPostRequest } = usePostRequest(
    endpoint,
    tokens
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files[]", file);
    });

    try {
      const response = await sendPostRequest(formData);

      const uploadedFileUrls = response.files.map((file) => file.url);

      // Update the form state immediately
      onChange({
        target: {
          name: field.name,
          value: uploadedFileUrls,
        },
      });
    } catch (error) {
      console.error("Failed to upload files", error);
      alert("Failed to upload file. Please try again.");
    }
  };

  return (
    <div className="form-field">
      <Typography variant="h6">{field.label}</Typography>
      <input
        type="file"
        multiple={field.multiple}
        accept={accept}
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <Button
        variant="outlined"
        className="upload-button"
        onClick={handleButtonClick}
        disabled={uploading}
      >
        {uploading ? <CircularProgress size={24} /> : field.title || "Upload"}
      </Button>
    </div>
  );
};

export default FileUpload;
