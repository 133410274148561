import React from "react";
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LabelM } from "./Typography";

export default function Dialog(props) {
  const { open, onClose, title, content, actions } = props;

  return (
    <MuiDialog
      sx={{
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          width: "600px",
          maxWidth: "100%",
          boxSizing: "border-box",
        },
      }}
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <LabelM style={{ marginTop: "10px" }}>{title}</LabelM>
      </DialogTitle>
      <DialogContent
        sx={{
          maxHeight: "500px",
          overflowY: "auto",
          padding: 2,
          boxSizing: "border-box",
        }}
      >
        {content}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MuiDialog>
  );
}
